//import Vue from 'vue';
export default class MapsService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    listarStatus(uf) {
        return this._http.post('utils/maps', {
            cd_apresentante: this._store.getters.cdApresentante,
            uf: uf
        });
    }
}
