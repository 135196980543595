import axios from 'axios';
import Vue from 'vue';
export default class TituloService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    recolocarEmFilaDeEnvio(titulo) {
        return this._http.post('titulo/fila/recolocar', {
            id_titulo: titulo.id_titulo,
            cd_apresentante: this._store.getters.cdApresentante
        });
    }

    filtrarTitulos(filtros, nr_pagina, nr_titulos_pagina) {
        if (filtros.ds_agrupador === '') {
            filtros.ds_agrupador = null;
        }

        return this._http.post(
            'consulta_titulos_paginada',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                pagina: nr_pagina,
                titulos_por_pagina: nr_titulos_pagina,
                filtros
            },
            {
                // headers: {
                //     Authorization: 'Bearer ' + this._store.getters.token
                // }
            }
        );
    }

    detalharTitulo(titulo) {
        return this._http.post('consulta_titulo/detalhe', {
            cd_apresentante: this._store.getters.cdApresentante,
            id_titulo: titulo.id_titulo
        });
    }

    detalharTituloView(titulo) {
        console.log(titulo);
        return this._http.post('consulta_titulo/detalhe', {
            cd_apresentante: titulo.cd_apresentante,
            id_titulo: titulo.id_titulo
        });
    }

    buscarAnexoTitulo(titulo, tipoAnexo) {
        return this._http.get('titulo/anexo', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                id_titulo: this._store.getters.getTitulo.id_titulo,
                tp_anexo: tipoAnexo
            }
        });
    }

    baixarInstrumentos(titulo) {
        return this._http.get('anexo/baixar', {
            responseType: 'arraybuffer',
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                id_titulo: titulo.id_titulo,
                tp_anexo: 'IP'
            }
        });
    }

    salvarOferta(data) {
        var response = null;
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        if (data.id) {
            let path = '/protestado/api/b2b/titulos-oferta/' + data.id + '/';
            response = Vue.http.put(url + path, data);
        } else {
            let path = '/protestado/api/b2b/titulos-oferta/';
            response = Vue.http.post(url + path, data);
        }
        return response;
    }

    desabilitarOferta(data) {
        var response = null;
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        if (data.id) {
            let path = '/protestado/api/b2b/titulos-oferta/?id=' + data.id;
            response = Vue.http.delete(url + path, data);
        } else {
            console.log('Não há ID fornecido');
        }
        return response;
    }

    async listaSituacoesTitulo(callback=null, allStatus = false) {

        let url = allStatus ? '/status-titulo' : '/status-titulo/' + this._store.getters.cdApresentante;

        await this._http
            .get(
                process.env.VUE_APP_RECUPERI_API + url,
                {}
            )
            .then(
                response => {
                    let situacoesObj = [];

                    situacoesObj.push({
                        cd_status: null,
                        nm_status: 'Selecione Status',
                        disabled: true
                    });
                    for (var [key, value] of Object.entries(response.body)) {
                        situacoesObj.push({
                            cd_status: value,
                            nm_status: key
                        });
                    }

                    situacoesObj.push({
                        cd_status: 99999,
                        nm_status: 'EM REVISÃO'
                    });

                    let storeName = allStatus ? 'setTodasSituacoesTitulo' : 'setSituacoesTitulo';

                    this._store.commit(storeName, situacoesObj);

                    if (callback) {
                        callback(situacoesObj);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    listaCarteiras() {
        this._http
            .post('carteira', {
                cd_apresentante: this._store.getters.cdApresentante
            })
            .then(
                response => {
                    let carteirasObj = [];

                    carteirasObj.push('Carteira');
                    for (let i in response.body) {
                        carteirasObj.push(response.body[i]);
                    }
                    this._store.commit('setCarteiras', carteirasObj);
                },
                error => {
                    console.log(error);
                }
            );
    }
    async getWalletWithSearch(search) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + `carteira/${search}`,
                {
                    params: {
                        cd_apresentante: this._store.getters.cdApresentante
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    uploadTilulos(file, fileInfo) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append(
            'json',
            JSON.stringify({
                cd_apresentante: this._store.getters.cdApresentante,
                id_user: this._store.getters.idUser,
                fileInfo
            })
        );

        return this._http.post('remessapre/xls', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    reapresentarTitulo(titulo) {
        return this._http.post(
            'api/remessa',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                titulos: [titulo]
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }
 
    updateStatus(id_titulo, cd_status, cd_irregularidade=null) {
        return this._http.patch(
            `titulos/${id_titulo}/status`,
            {
                cd_status: cd_status,
                cd_irregularidade: cd_irregularidade
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    async trocarContatoDevedor(devedor) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'titulo/correcao',
                devedor,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response;
        } catch (error) {
            throw error?.response;
        }
    }

    desativarMailing(titulo) {
        return this._http.put(
            'titulo/desativar_mensageria',
            {
                cd_apresentante: titulo.cd_comprador || titulo.cd_apresentante,
                id_titulo: titulo.id_titulo
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    async getCoodevedores(titulo) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'titulo/devedor',
                {
                    params: {
                        cd_apresentante: titulo.cd_apresentante,
                        id_titulo: titulo.id_titulo
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async addCoodevedor(coodevedor) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'titulo/devedor',
                coodevedor,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    async deleteCoodevedor(coodevedor) {
        try {
            const response = await axios.delete(
                process.env.VUE_APP_RECUPERI_API + 'titulo/devedor',
                {
                    params: {
                        cd_apresentante: this._store.getters.cdApresentante,
                        id_titulo: coodevedor.id_titulo,
                        id_devedor: coodevedor.id_devedor
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    exportarTitulosXls(filtros) {
        return this._http.post(
            'consulta_titulos_xls',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                modelo_relatorio: 'xls'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    exportarTitulosEmails(filtros, emails, is_used) {
        return this._http.post('exportar_titulos_sqs', {
            is_used: is_used,
            cd_apresentante: this._store.getters.cdApresentante,
            filtros,
            emails,
            modelo_relatorio: 'xls'
        });
    }

    tituloimpressao(titulo) {
        return this._http.post(
            'consulta_relatorio_detalhe_pdf',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                id_titulo: titulo.id_titulo
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    historicoImpressao(titulo) {
        return this._http.post(
            'consultar_titulo/historico/emails',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                ds_nosso_numero: titulo.ds_nosso_numero
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }
    historicoImpressaoComponent(titulo) {
        return this._http.post(
            'consultar_titulo/historico/emails',
            {
                cd_apresentante: titulo.cd_apresentante,
                ds_nosso_numero: titulo.ds_nosso_numero
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    relatorioPdfEmail(filtros, emails) {
        return this._http.post(
            'exportar_titulos_sqs',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                emails,
                modelo_relatorio: 'pdf'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    relatorioPdfTela(filtros) {
        return this._http.post(
            'consulta_relatorio_lista_pdf',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                modelo_relatorio: 'pdf'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    //relatório abaixo, era modelo_relatorio: 'pdf_detalhado', foi transformado em csv
    relatoriodetalhes(filtros, emails) {
        return this._http.post(
            'exportar_titulos_sqs',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                emails,
                modelo_relatorio: 'csv'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    corrigeTitulo(
        id_titulo,
        nm_devedor_abreviado,
        ds_endereco_devedor_abreviado,
        cd_status
    ) {
        return this._http.post('titulo/correcao', {
            cd_apresentante: this._store.getters.cdApresentante,
            id_titulo: id_titulo,
            nm_devedor_cra: nm_devedor_abreviado,
            ds_endereco_devedor_cra: ds_endereco_devedor_abreviado,
            cd_status: cd_status
        });
    }

    cancelaTitulo(data) {
        return this._http.post('api/cancelamento/lote', {
            cd_apresentante: this._store.getters.cdApresentante,
            titulos: data,
            tipo_cancelamento: 'C'
        });
    }

    anuenciaTitulo(data) {
        return this._http.post('api/cancelamento/lote', {
            cd_apresentante: this._store.getters.cdApresentante,
            titulos: data,
            tipo_cancelamento: 'A'
        });
    }

    desistenciaTitulo(data) {
        return this._http.post('api/cancelamento/lote', {
            cd_apresentante: this._store.getters.cdApresentante,
            titulos: data,
            tipo_cancelamento: 'D'
        });
    }

    cancelaNegociacao(data) {
        return this._http.post('titulos/solicitar-cancelamento-negociacao/bulk', data);
    }

    verificaUsuarioInativo() {
        return this._http.post('verifica_usuario_inativo', {
            cd_apresentante: this._store.getters.cdApresentante
        });
    }

    async getRelatorioValoresCancelamentoDesistencia(data) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'titulos/simulacao-calculo-desistencia',
                {id_titulo: data},
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response
        } catch (error) {
            throw error?.response;
        }
    }

    verificaStatus() {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return Vue.http.get(url + '/emails/api/status', {}, {});
    }

    cancelarComando(id_titulo, cd_apresentante, cd_status) {
        return this._http.post('cancelar_comando', {
            id_titulo: id_titulo,
            cd_apresentante: cd_apresentante,
            cd_status: cd_status
        });
    }

    getCartorios(cidade) {
        return this._http.get('cartorios', {
            params: {
                cd_ibge_comarca: cidade
            }
        });
    }

    listarComentarios(id_titulo) {
        return this._http.get('titulo/comentario', {
            params: {
                id_titulo: id_titulo
            }
        });
    }

    criarComentario(comentario, id_titulo) {
        return this._http.post('titulo/comentario', {
            id_titulo: id_titulo,
            comentario: comentario
        });
    }

    async getMunicipios() {
        return this._http.get('utils/municipios', {});
    }

    salvarComarca(
        protocolo,
        dataApontamento,
        cartorio,
        municipio,
        id_titulo,
        cd_apresentante
    ) {
        return this._http.put('consulta_titulo/detalhe', {
            cd_apresentante: cd_apresentante,
            id_titulo: id_titulo,
            cd_municipio: municipio,
            nu_cartorio: cartorio,
            nu_protocolo: protocolo,
            dt_protocolo: dataApontamento
        });
    }

    async getListFiles(params) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API +
                    'titulo/arquivo',
                {
                    params,
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
    async downloadFile(params) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API +
                    'titulo/arquivo/download',
                {
                    params,
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    },
                    responseType: 'arraybuffer'
                }
            );

            return response;
        } catch (error) {
            throw error?.response;
        }
    }
    async deleteHistoryStatus(id_historico) {
        try {
            const response = await axios.delete(
                process.env.VUE_APP_RECUPERI_API + 'titulo/historico',
                {
                    data: {
                        id_historico: id_historico,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    solicitaInstrumento(id_titulo, cd_apresentante) {
        return this._http.put(`instrumentoprotesto/solicitacao/titulo/${id_titulo}`, {
            cd_apresentante: cd_apresentante
        });
    }

    exportarFinanceiroResumido(filtros, emails) {
        return this._http.post(
            'exportar_titulos_sqs',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                emails,
                modelo_relatorio: 'repasse_relatorio_xls'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    async consultarTitulosRepasse(pagina, titulosPorPagina, filtros) {
        return this._http.post('consulta_titulo/repasse', {
            cd_apresentante: this._store.getters.cdApresentante,
            filtros,
            pagina: pagina,
            titulos_por_pagina: titulosPorPagina
        });
    }

    async atualizarAgrupador(id_titulo, novo_ds_agrupador) {
        return this._http.put('titulo/atualizacao_agrupador', {
            cd_apresentante: this._store.getters.cdApresentante,
            id_titulo: id_titulo,
            novo_ds_agrupador: novo_ds_agrupador
        });
    }

    async atualizarAgrupadorLote(titulos, novo_ds_agrupador) {
        return this._http.put('titulo/atualizacao_agrupador/lote', {
            cd_apresentante: this._store.getters.cdApresentante,
            titulos: titulos,
            novo_ds_agrupador: novo_ds_agrupador
        });
    }

    async baixarInstrumentosLote(titulos, emails) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API +
                    'instrumentoprotesto/baixar/lote',
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    titulos: titulos,
                    emails: emails
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async invalidarInstrumento(titulo) {
        try {
            const response = await axios.patch(
                process.env.VUE_APP_RECUPERI_API +
                    'instrumentoprotesto/invalida',
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    id_anexo_instrumento: titulo.id_anexo_instrumento,
                    ds_nosso_numero: titulo.ds_nosso_numero
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async anexarArquivo(objetoAnexo) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + `titulo/${this._store.getters.getTitulo.id_titulo}/upload/anexo`,
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    ds_nosso_numero: objetoAnexo.ds_nosso_numero,
                    arquivos_selecionados: objetoAnexo.arquivos_selecionados,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }
    async substituirAnexo(objetoAnexo) {
        try {
            const response = await axios.put(
                process.env.VUE_APP_RECUPERI_API + `titulo/${this._store.getters.getTitulo.id_titulo}/upload/anexo`,
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    ds_nosso_numero: objetoAnexo.ds_nosso_numero,
                    arquivos_selecionados: objetoAnexo.arquivos_selecionados,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    async anexarArquivoLote(listaAnexos) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'titulo/upload/anexo/lote',
                {
                    id_titulo: this._store.getters.getTitulo.id_titulo,
                    cd_apresentante: this._store.getters.cdApresentante,
                    lista_de_anexos: listaAnexos
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async baixarAnexo(titulo) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'anexo/baixar',
                {
                    params: {
                        cd_apresentante: this._store.getters.cdApresentante,
                        id_titulo: titulo.id_titulo,
                        tp_anexo: 'AN'
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    },
                    responseType: 'arraybuffer'
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
}
