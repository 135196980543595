var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("HeadbarPage", { attrs: { title: "Títulos > Anuência em Massa" } }),
      _c("UploadEmMassa", {
        attrs: {
          title: "Anuência em Massa",
          textAreaLabel: "Cole aqui os títulos que deseja solicitar a Anuência",
          textAreaHint:
            "Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5",
          instrucaoUpload:
            "Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para anuência.",
          textAreaPlaceholder:
            "Cole aqui os títulos que deseja solicitar anuência em massa.",
          dica:
            "Dica: Anuência pode ser solicitada somente para os títulos com os seguintes status: 102 Protestado, 109 Protestado por Edital, 123 Protestado por Edital(RJ/BA), 221 Enviado a Protesto (será solicitado uma Pré-anuência), 225 Apontado (será solicitado uma Pré-anuência)."
        },
        on: { buscarTitulos: _vm.buscarTitulos }
      }),
      _c("TableWithSelections", {
        ref: "tableWithSelections",
        attrs: {
          titulos: _vm.titulos,
          totalTitulos: _vm.totalTitulos,
          loading: _vm.loading
        },
        on: {
          selecionados: _vm.setSelecionados,
          changeOptionsPage: _vm.changeOptionsPage,
          selecionarTodos: _vm.setTodosSelecionados
        }
      }),
      _c("CardConfirmacaoEnvioEmMassa", {
        attrs: {
          selecionados: _vm.processarSelecionados,
          avisoRetorno: _vm.avisoRetorno,
          loadingFiltros: _vm.loadingFiltros,
          resultadoCheck: _vm.resultadoCheck,
          titulosEnviadosCheck: _vm.titulosEnviadosCheck,
          type: "Anuência"
        },
        on: { enviar: _vm.anuenciaMassa }
      }),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }