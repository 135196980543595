<template>
    <v-container>
        <HeadbarPage :title="'Títulos > Cancelamento em Massa'"></HeadbarPage>
        <UploadEmMassa
            @buscarTitulos="buscarTitulos"
            :title="'Cancelamento em Massa'"
            :textAreaLabel="'Cole aqui os títulos que deseja solicitar o Cancelamento'"
            :textAreaHint="'Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5'"
            :instrucaoUpload="'Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para cancelamento'"
            :textAreaPlaceholder="'Cole aqui os títulos que deseja solicitar Cancelamento em massa.'"
            :dica="'Dica: o cancelamento pode ser solicitado somente para os títulos com os seguintes status: 102 Protestado, 109 Protestado por Edital, 123 Protestado por Edital(RJ/BA) , 250 Solicitado Anuência, 251 Enviado à Anuência, 144 Anuência confirmada em Cartório'"
        />
        <TableWithSelections 
            ref="tableWithSelections"
            :titulos="titulos"
            :totalTitulos="totalTitulos"
            :loading="loading"
            @selecionados="setSelecionados"
            @changeOptionsPage="changeOptionsPage"
            @selecionarTodos="setTodosSelecionados"
        />
        <CardConfirmacaoEnvioEmMassa 
            :selecionados="processarSelecionados"
            :avisoRetorno="avisoRetorno"
            :loadingFiltros="loadingFiltros"
            :resultadoCheck="resultadoCheck"
            :titulosEnviadosCheck="titulosEnviadosCheck"
            :type="'Cancelamento'"
            @enviar="cancelamentoMassa"
        />
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
        
    </v-container>
</template>
<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';

export default {
    name: 'CancelamentoEmMassaV2',
    components: {
        HeadbarPage: () => import('@/components/atoms/HeadbarPage'),
        UploadEmMassa: () => import('@/components/organisms/UploadEmMassa'),
        TableWithSelections: () => import('@/components/atoms/TableWithSelections'),
        CardConfirmacaoEnvioEmMassa: () => import('@/components/organisms/CardConfirmacaoEnvioEmMassa'),
    },
    data(){
        return{
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            filtros: {
                cd_status: '',
            },
            tituloService: new TituloService(Vue.http, this.$store),
            options: {
                page: 1,
                itemsPerPage: 300
            },
            titulos: [],
            todosTitulos: [],
            totalTitulos: 0,
            loading: false,
            selecionados: [],
            selecionarTodos: false,
            avisoRetorno: [],
            loadingFiltros: false,
            resultadoCheck: false,
            titulosEnviadosCheck: true,
        }
    },
    computed: {
      processarSelecionados() {
        if (this.selecionarTodos) {
          return this.todosTitulos;
        } else {
          return this.selecionados;
        }
      }  
    },
    methods: {
        setSelecionados(selecionados) {
            this.selecionados = selecionados;
        },

        setTodosSelecionados(selecionarTodos) {
            this.selecionarTodos = selecionarTodos
            if (selecionarTodos) {
                this.buscarTitulos(this.filtros)
            }
        },

        formatarDocumento() {
            var nu_identificacao_devedor = this.filtros
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        cancelamentoMassa(data){
            if(this.loadingFiltros){
                return;
            }
            this.loadingFiltros = true;
            this.tituloService.cancelaTitulo(data).then(response => {
                this.titulosEnviadosCheck = false;
                this.resultadoCheck = true;
                this.titulosEnviadosCheck = false;
                this.loadingFiltros = false;
                console.log(response.body);
                this.avisoRetorno = response.body;
            }).catch(error => {
                this.snackbar = true;
                this.colorSnackbar = 'error';
                this.mensagemSnackbar = 'Erro ao enviar títulos para cancelamento';
                console.log(error);
            });

        },
        
        buscarTitulos(filtros, resetOptions = false) {
            this.filtros = filtros;
            this.loading = true;

            if (resetOptions) {
                this.options.page = 1;
                this.options.itemsPerPage = 300;

                this.$refs['tableWithSelections'].resetSelecionados();
                this.selecionados = [];
            }

            let page = this.options.page;
            let itemsPerPage = this.options.itemsPerPage;

            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            this.filtros.cd_status = '102,109,123,250,251,144',
            this.filtros.nu_identificacao_devedor = this.formatarDocumento();

            let use_filtros = JSON.parse(JSON.stringify(this.filtros));


            if (this.selecionarTodos) {
                itemsPerPage = this.totalTitulos;
                use_filtros['linhas_full'] = 1;
                this.todosTitulos = [];
            }

            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(use_filtros, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.exportActive = false;

                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                if (this.selecionarTodos) {
                                    this.todosTitulos = titulos;
                                } else {
                                    this.titulos = titulos;
                                }
                                
                                this.totalTitulos = total;

                                resolve();

                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },

        changeOptionsPage(options) {
            if (this.options.itemsPerPage != options.itemsPerPage || this.options.page != options.page) {
                this.options = options;
                this.buscarTitulos(this.filtros)
            }
        }
    }
}
</script>