<template>
    <div align="left" justify="center" class="">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <div align="center" justify="center" class="pa-3">
            <v-tooltip
                v-if="mostrarBotaoFlutuante && selecionados.length !== 0"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        fab
                        dark
                        color="green"
                        fixed
                        right
                        bottom
                        @click="modalEmail = true"
                    >
                        <v-icon dark>download</v-icon>
                    </v-btn>
                </template>
                <span>Baixar instrumentos</span>
            </v-tooltip>
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-sync </v-icon
                    >Títulos > Baixar Instrumentos de Protesto em Massa
                </v-card-text>
            </v-card>
            <v-card @keyup.native.enter="botaoFiltrar" class="mt-2 mb-3">
                <v-row align="center" class="mx-6 mt-3 pt-3">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="8">
                        <v-card-text
                            v-bind:style="{ color: primaryColorFont }"
                            class="
                                text-left
                                font-weight-black
                                display-1
                                pl-0
                            "
                        >
                            <v-icon large class="ma-2">
                                mdi-download-circle-outline </v-icon
                            >Baixar Instrumentos de Protesto em lote
                        </v-card-text>
                        <v-textarea
                            solo
                            small
                            label="Cole aqui os títulos que deseja solicitar o download de Instrumento de Protesto"
                            hint="Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5"
                            placeholder="Exemplo:  477977/1, 721603/6, 755202/5"
                            clearable
                            v-model="filtros.ds_nosso_numero"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="4" class="mt-2">
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-card class="grey white--text white--text">
                                <v-icon class="ma-2 text-left white--text">
                                    mdi-chevron-down </v-icon
                                >fazer upload de arquivo XLS
                            </v-card>
                            <v-card-text
                                class="font-weight-light text-left title-1"
                            >
                                Utilize o campo abaixo para fazer upload de
                                arquivo com os títulos em massa para downoad de
                                Instrumentos de Protesto. Formatos compatíveis:
                                .xls/.xlsx/.xlsm Limite de tamanho: 60mb
                            </v-card-text>
                            <v-btn
                                block
                                plain
                                small
                                class="grey white--text"
                                :href="link_modelo"
                            >
                                <v-icon class="ma-2 text-left">
                                    mdi-file-download </v-icon
                                >Baixar modelo
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" class="mt-2">
                            <v-file-input
                                class="mb-n7 white--text"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                                label="Selecione o arquivo (.xls/.xlsx/.xlsm)"
                                show-size
                                chips
                                solo
                                ref="myFileInput"
                                id="fileInput"
                                name="file"
                                @change="uploadFileReference"
                            ></v-file-input>
                            <v-card-text
                                v-if="fazendoUpload"
                                class="font-weight-light text-left title-1"
                            >
                                <v-btn
                                    color="green"
                                    x-small
                                    elevation="0"
                                    fab
                                    class="ma-1"
                                    ><v-icon class="white--text"
                                        >mdi-check</v-icon
                                    ></v-btn
                                >
                                Títulos verificados:
                                {{ retornaDadosXls() }} linhas</v-card-text
                            >
                            <v-card-text
                                v-if="fazendoUpload"
                                class="text-left title-1"
                            >
                                <v-btn
                                    color="green"
                                    x-small
                                    elevation="0"
                                    fab
                                    class="ma-1"
                                    ><v-icon class="white--text"
                                        >mdi-magnify</v-icon
                                    ></v-btn
                                >
                                Para confirmar, clique em Filtrar
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    small
                                    class="grey lighten-4"
                                >
                                    <v-card-text
                                        class="font-weight-light black--text"
                                    >
                                        <v-icon class="primary--text lighten-2">
                                            search
                                        </v-icon>
                                        Filtrar Resultados
                                    </v-card-text>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="my-3">
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                label="CPF/CNPJ"
                                                hint="Informe o Número do CPF/CNPJ"
                                                placeholder="Ex.:000.000.000-00"
                                                v-model="
                                                    filtros.nu_identificacao_devedor
                                                "
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class=""
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                label="Nome"
                                                hint="Informe o nome para efetuar a busca"
                                                placeholder="Ex.: José"
                                                clearable
                                                v-model="filtros.nm_devedor"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="10"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-select
                                                solo
                                                small
                                                v-model="filtros.cd_uf_devedor"
                                                :items="listaUfs"
                                                label="UF"
                                                :return-object="true"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row class="mx-0 mt-2 mb-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col
                        cols="10"
                        xs="6"
                        sm="6"
                        md="3"
                        lg="3"
                        class="pr-9"
                        align="center"
                    >
                        <v-btn
                            block
                            class="d-flex justify-center"
                            color="primary"
                            :loading="loadingFiltros"
                            @click="botaoFiltrar"
                        >
                            Filtrar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>

            <v-dialog v-if="modalEmail" v-model="modalEmail" max-width="500">
                <v-card>
                    <v-card-text>
                        <v-card-title class="headline primary--text"
                            >Instrumentos de Protesto via e-mail</v-card-title
                        >
                        <v-spacer></v-spacer>
                        <v-form
                            ref="formEmail"
                            v-model="emailValido"
                            @keyup.native.enter="checarEmailValido()"
                        >
                            <v-text-field
                                prepend-icon="person"
                                name="email"
                                label="E-mail"
                                type="email"
                                v-model="emailsInstrumento"
                                :rules="emailRegras"
                                required
                                data-cy="signinEmailField"
                            >
                            </v-text-field>
                        </v-form>
                        <v-card-subtitle
                            >Digite o e-mail onde deseja receber os Instrumentos
                            de Protesto. Caso deseje receber em mais de um
                            e-mail, favor, preenchê-los separando por
                            vírgula.</v-card-subtitle
                        >
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="baixarInstrumentos()"
                            :disabled="!emailValido"
                            data-cy="enviarEmailsInstrumentos"
                        >
                            Enviar
                            <v-icon class="ml-1">chevron_right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemRetornoSnackbar"
                @fecharSnackbar="snackbar = false"
            />

            <v-data-table
                :headers="headers"
                :items="titulos"
                item-text="titulo"
                v-model="selecionados"
                :options.sync="options"
                :loading="loading"
                :single-select="false"
                item-key="id_titulo"
                show-select
                :footer-props="{
                    itemsPerPageOptions: [50, 1000, 5000, -1],
                    itemsPerPageText: 'Itens por página:'
                }"
                :server-items-length="totalTitulos"
                loading-text="Pesquisando títulos..."
                no-data-text="Preencha os filtros e clique em filtrar..."
                no-results-text="Nenhum título encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.ds_nosso_numero }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.ds_nosso_numero
                    }}</span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDocumento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.nm_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ getDescSituacao(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
            </v-data-table>
            <v-card class="mt-3">
                <v-row class="ma-0">
                    <v-spacer></v-spacer>
                    <v-col sm="6" md="3" lg="4" class="d-flex justify-end pr-9">
                        <v-tooltip top :disabled="selecionados.length !== 0">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        class="d-flex justify-center"
                                        color="green white--text"
                                        @click="modalEmail = true"
                                        :disabled="!selecionados.length"
                                        v-intersect="onIntersect"
                                    >
                                        Baixar Instrumentos
                                        <v-icon class="ml-2"
                                            >mdi-chevron-right</v-icon
                                        >
                                    </v-btn>
                                </div>
                            </template>
                            <span
                                >Selecione um ou mais títulos para liberar o
                                comando</span
                            >
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
import * as Formatter from '@/helpers/Formatter';
import XLSX from 'xlsx';
import {
    getMensagemRequest,
    getDescritivoAgrupador,
    listagemUF
} from '@/helpers/Utilitarios';
import Loading from 'vue-loading-overlay';

export default {
    name: 'AgrupadorEmMassa',
    components: {
        Loading
    },
    data() {
        return {
            tituloService: new TituloService(Vue.http, this.$store),
            modalEmail: false,
            emailValido: false,
            emailRegras: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            emailsInstrumento: '',
            mostrarBotaoFlutuante: true,
            listaUfs: listagemUF,
            snackbarTimeout: '4000',
            snackbarColor: 'green',
            snackbar: false,
            mensagemRetornoSnackbar: 'Teste',
            link_modelo: '/Planilha-atualizacao-em-massa-Recuperi.xlsx',
            filtrar: false,
            selecionados: [],
            ultimoFiltro: null,
            exportActive: true,
            registros_pagina: 10,
            numero_paginas: 1,
            showPagination: false,
            loadingFiltros: false,
            situacoesTitulo: [],
            totalTitulos: 0,
            titulos: [],
            loading: false,
            loadingInicial: false,
            options: {
                page: 1,
                itemsPerPage: 50
            },
            primaryColorFont: '#757575',
            filtros: {
                cd_status: '102',
                nm_devedor: null,
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                ds_agrupador: null,
                apontamento_nu_protocolo: null,
                dt_vencimento_range: [],
                dt_cadastro_range: [],
                dt_atualizacao_range: [],
                dt_vencimento_range_fmt: null,
                dt_atualizacao_range_fmt: null,
                dt_cadastro_range_fmt: null,
                uploadOk: false,
                loadingUpload: false,
                desabilitaBoxEnvio: false,
                tem_ip_anexo: 1
            },
            file: null,
            customerCountRead: 0,
            dadosXls: null,
            fazendoUpload: false,
            headers: [
                {
                    text: 'Código do título',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                { text: 'Nome', sortable: true, value: 'nm_devedor' },
                { text: 'Status', sortable: true, value: 'cd_status' },
                {
                    text: 'ID Anexo',
                    sortable: true,
                    value: 'id_anexo_instrumento'
                }
            ]
        };
    },
    watch: {
        pagina() {
            this.aplicarFiltros();
        },
        options: {
            handler() {
                if (this.filtrar) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        },
        modalEmail() {
            if (!this.modalEmail) {
                this.emailsInstrumento = '';
            }
        }
    },
    computed: {
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        usuarioAdmin() {
            if (this.$store.getters.isAdmin == 1) {
                return true;
            }
            return false;
        }
    },
    async mounted() {
        this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
        this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];
        this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
        this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];
        this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[0];
        this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[1];

        this.loadingInicial = true;
        this.situacoesTitulo = this.$store.getters.situacoesTitulo;
        this.situacoesTitulo.push({
            cd_status: 99999,
            nm_status: 'EM REVISÃO'
        });
        this.loadingInicial = false;

        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }

        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    },
    methods: {
        getMensagemRequest,
        getDescritivoAgrupador,
        checarEmailValido() {
            if (this.emailValido) {
                this.baixarInstrumentos();
            }
        },
        onIntersect(entries) {
            const isIntersecting = entries[0].isIntersecting;
            this.mostrarBotaoFlutuante = !isIntersecting;
        },
        botaoFiltrar() {
            this.filtrar = true;
            this.ultimoFiltro = this.filtros;
            this.aplicarFiltros();
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        aplicarFiltros() {
            if (this.dadosXls == null) {
                this.filtros.ds_nosso_numero = this.filtros.ds_nosso_numero;
            } else {
                this.filtros.ds_nosso_numero = this.dadosXls.toString();
            }

            this.buscarTitulos().then(data => {
                this.titulos = data.titulos;
                this.totalTitulos = data.total;
                if (data.total === 0) {
                    this.numero_paginas = 1;
                } else {
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
            });
        },
        buscarTitulos() {
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            if (this.ultimoFiltro == null) {
                this.ultimoFiltro = this.filtros;
            }
            this.ultimoFiltro.nu_identificacao_devedor = this.formatarDocumento();
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    this.tituloService
                        .filtrarTitulos(this.ultimoFiltro, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                this.loadingFiltros = false;
                                this.loading = false;
                                resolve({
                                    titulos,
                                    total
                                });
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    console.log(e);
                    reject();
                }
            });
        },
        mostrarSnackbar(snackbarColor, mensagemRetornoSnackbar) {
            this.snackbarColor = snackbarColor;
            this.snackbar = true;
            this.mensagemRetornoSnackbar = mensagemRetornoSnackbar;
        },
        async baixarInstrumentos() {
            try {
                this.loadingInicial = true;
                const titulos = this.selecionados
                    .map(e => e.id_titulo)
                    .join(',');
                await this.tituloService.baixarInstrumentosLote(
                    titulos,
                    this.emailsInstrumento
                );

                this.mostrarSnackbar(
                    'success',
                    'Solicitação de Instrumentos de Protesto realizada com sucesso!'
                );
                this.modalEmail = false;
            } catch (e) {
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao solicitar Instrumentos de Protesto'
                );
                this.mostrarSnackbar('error', mensagem);
            } finally {
                this.loadingInicial = false;
            }
        },
        formatarDocumento() {
            var nu_identificacao_devedor = this.ultimoFiltro
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        uploadFileReference(e) {
            if (!e) return null;
            this.file = e;
            this.uploadOk = false;
            const reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, { type: 'binary' });
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet);
                const finalJsonData = JSON.stringify(rowObject, undefined, 4);
                let rows = JSON.parse(finalJsonData);
                vm.customerCountRead = rows.length;
                vm.dadosXls = rows.map(e => e.ds_nosso_numero);
            };
            reader.readAsBinaryString(this.file);
            this.fazendoUpload = true;
        },
        retornaDadosXls() {
            return this.customerCountRead;
        }
    }
};
</script>
