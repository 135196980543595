<template>
    <div align="left" justify="center" class="">
        <ConfirmacaoModal
            v-if="mostrarConfirmacao"
            :showDialog="mostrarConfirmacao"
            :tituloModal="tituloModal"
            :textoModal="textoModal"
            :btnConfirmarEmit="btnConfirmarEmit"
            @cancelarComando="cancelarComando"
            @fecharModal="mostrarConfirmacao = false"
        />
        <v-alert
            v-show="mensagem"
            dense
            class="ma-3 white--text"
            dismissible
            :color="alertColor"
            :type="alertType"
            border="left"
            >{{ mensagemRetorno }}</v-alert
        >
        <!-- Dialog Geral -->
        <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Dialog de confirmação do cancelamento -->
            <v-dialog
                v-model="dialogConfirmationGeneric"
                max-width="400"
                @click:outside="cancelDialogGeneric"
            >
                <v-card>
                    <v-card-title class="headline primary--text">{{
                        genericDialog.title
                    }}</v-card-title>

                    <v-card-text>
                    {{ genericDialog.text }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        
                        <v-btn
                            color="green darken-1"
                            text
                            @click="confirmaDialogGeneric"
                            data-cy="confirmaCancelamento"
                            >{{ genericDialog.labelConfirmBtn }}
                        </v-btn>

                        <v-btn
                            color="red darken-1"
                            text
                            
                            @click="cancelDialogGeneric"
                            >{{genericDialog.labelCancelBtn}}</v-btn
                        >

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialogConfirmation"
                max-width="400"
                @click:outside="negaCancelamento"
            >
                <v-card>
                    <v-card-title class="headline primary--text">{{
                        tipo_cancelamento.substring(2)
                    }}</v-card-title>

                    <v-card-text v-show="!dialogResponse">
                        Deseja realmente realizar {{ tipo_cancelamento }} do
                        título {{ titulo.ds_nosso_numero }}?
                        {{ dialogExtra }}
                    </v-card-text>
                    <v-card-text v-show="dialogResponse">{{
                        dialogText
                    }}</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            v-show="!dialogResponse"
                            @click="confirmaCancelamento"
                            data-cy="confirmaCancelamento"
                            >{{ labelConfirm ? labelConfirm : 'Sim' }}
                        </v-btn>

                        <v-btn
                            color="red darken-1"
                            text
                            v-show="!dialogResponse"
                            @click="negaCancelamento"
                            >Não</v-btn
                        >

                        <v-btn
                            color="green darken-1"
                            text
                            v-show="dialogResponse"
                            data-cy="confirmaResponse"
                            @click="fechaCancelamento"
                            >Ok!
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                v-model="dialogCancelamentoNegociacao"
                max-width="400"
                @click:outside="dialogCancelamentoNegociacao=false"
            >
                <v-card>
                    <v-card-title class="headline primary--text">
                        Cancelamento de Negociação
                    </v-card-title>

                    <v-card-text v-show="!dialogResponse">
                        Deseja realmente solicitar o cancelamento da negociação do título {{ titulo.ds_nosso_numero }}?
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            v-show="!dialogResponse"
                            @click="confirmaCancelamentoNegociacao"
                            data-cy="confirmaCancelamentoNegociacao"
                            >Sim
                        </v-btn>

                        <v-btn
                            color="red darken-1"
                            text
                            v-show="!dialogResponse"
                            @click="dialogCancelamentoNegociacao=false"
                            >Não</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="modalInformativoPreAnuencia" max-width="800px">
                <v-card class="pa-3">
                    <v-card-text class="ma-2 pa-2 font-weight-bold title"
                        >Deseja programar o envio da anuência?</v-card-text
                    >
                    <v-card-text class="ma-2 pa-2">
                        O título/dívida ainda está com status “Apontado”.
                        Portanto, o envio da anuência será programado para ser
                        feito automaticamente logo após a formalização do
                        protesto, ou seja, depois da intimação do devedor
                        (presencial, carta com AR ou por edital) e da lavratura
                        do instrumento de protesto.
                    </v-card-text>
                    <v-card-text class="ma-2 pa-2">
                        O Apresentante/Credor confirma que o devedor está ciente
                        que:
                    </v-card-text>
                    <v-card-text class="ma-2 pa-2">
                        - Como condição da negociação/pagamento da dívida
                        enviada a protesto, caberá ao devedor pagar os
                        emolumentos diretamente ao cartório depois que o
                        procedimento de protesto for concluído;
                    </v-card-text>
                    <v-card-text class="ma-2 pa-2">
                        - Ele(a) poderá receber uma intimação do cartório sobre
                        o protesto;
                    </v-card-text>
                    <v-card-text class="ma-2 pa-2">
                        - A anuência eletrônica está programada e será enviada
                        ao cartório apenas após a formalização do protesto.
                    </v-card-text>
                    <v-card-text class="ma-2 pa-2">
                        A Recuperi irá enviar e-mail(s) ao devedor no endereço
                        de e-mail cadastrado pelo Apresentante/Credor com
                        orientações sobre o procedimento de protesto, a
                        programação do envio da anuência e pagamento de
                        emolumentos.
                    </v-card-text>
                    <v-checkbox
                        class="ma-2 pa-2"
                        v-model="checkbox"
                        data-cy="anuenciaTermosCheckbox"
                        :label="
                            `O Apresentante/Credor autoriza a Recuperi a programar o envio de anuência.`
                        "
                    ></v-checkbox>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green white--text"
                            :disabled="!checkbox"
                            @click="confirmaCancelamento"
                            data-cy="confirmarPreAnuencia"
                            >Confirmar
                        </v-btn>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="negaCancelamento"
                            >Cancelar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- fim do Dialog de confirmação do cancelamento -->

            <!-- Informações sobre o titulo -->
            <v-card>
                <v-card-title class="pa-0 title-details-head">
                    <v-toolbar flat dark color="primary">
                        <v-btn
                            class="ml-0"
                            icon
                            dark
                            @click="closeDialog"
                            data-cy="fecharModalTitulo"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Detalhes do Título </v-toolbar-title>
                        <div class="py-0 pr-0 pl-4" v-if="superUsuario">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        :content="lenOfertasGlobais"
                                        :value="lenOfertasGlobais"
                                        color="error"
                                        overlap
                                    >
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            dark
                                            small
                                            :disabled="ativaBotaoOferta"
                                            :loading="ofertaGlobalLoading"
                                            class="white--text"
                                            :style="
                                                lenOfertasGlobais
                                                    ? 'background-color: #103c6b'
                                                    : 'background-color: #b8c4d4'
                                            "
                                        >
                                            <v-icon dark>
                                                mdi-sale
                                            </v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span>
                                    {{
                                        lenOfertasGlobais
                                            ? 'Título atingido por uma ou mais Ofertas Globais ativas'
                                            : 'Título não atingido por Ofertas Globais ativas'
                                    }}
                                </span>
                            </v-tooltip>
                        </div>
                        <div class="py-0 pr-0 pl-4" v-if="superUsuario">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        :content="lenOfertasTitulo"
                                        :value="lenOfertasTitulo"
                                        color="error"
                                        overlap
                                    >
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            dark
                                            small
                                            :disabled="ativaBotaoOferta"
                                            :loading="ofertaTituloLoading"
                                            class="white--text"
                                            :style="
                                                lenOfertasTitulo
                                                    ? 'background-color: #103c6b'
                                                    : 'background-color: #b8c4d4'
                                            "
                                        >
                                            <v-icon dark>
                                                mdi-cash-multiple
                                            </v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span>
                                    {{
                                        lenOfertasTitulo
                                            ? 'Título possui Oferta Título ativas'
                                            : 'Título não possui Ofertas Título ativas'
                                    }}
                                </span>
                            </v-tooltip>
                        </div>
                        <v-spacer />
                        <div
                            style="display: flex; gap: 12px; padding-right: 32px"
                        >
                            <!-- Botão em Desenvolvimento, aguardando url de requisição para entrar em funcionamento -->
                            <v-btn
                                class="secondary text-center white--text"
                                :loading="loadingTituloImpressao"
                                @click="historicoImpressao(titulo)"
                                data-cy="imprimirHistorico"
                            >
                                Histórico de E-mails
                                <v-icon class="ma-2" small>mdi-printer</v-icon>
                            </v-btn>
                            <v-btn
                                class="secondary text-center white--text"
                                :loading="loadingTituloImpressao"
                                @click="tituloImpressao(titulo)"
                            >
                                Dados do Título
                                <v-icon class="ma-2" small>mdi-printer</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>

                    <div v-if="alertaRevisao.length>0" class="revisao-alerta">
                       <span v-for="item in alertaRevisao" v-bind:key="item">{{ item }}</span>
                    </div>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <DetalheTitulo
                        :titulo="titulo"
                        :setAtualizarTitulo="setAtualizarTitulo"
                        :mostrarConfirmacao="mostrarConfirmacao"
                        :habilitaCorrecao="habilitaCorrecao"
                        @habilitaDialogOferta="habilitaDialOferta(titulo)"
                        @fecharModal="closeDialog"
                        @showSnackbar="snackbarDetalheTitulo"
                        @atualizarTitulo="atualizarTitulo"
                    />
                    <v-expansion-panels class="pa-3">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <h2 class="mt-2">
                                    <v-icon class="grey--text mr-3"
                                        >mdi-email</v-icon
                                    >Histórico de E-mails
                                </h2>
                                <template v-slot:actions>
                                    <v-icon color="primary"> $expand </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card
                                    v-show="historicoCheck"
                                    class="mr-3 pr-3"
                                >
                                    <p class="ma-3 pa-3">
                                        Nossa plataforma realiza envios
                                        automáticos de e-mails a todos os
                                        devedores, por regra esses devedores
                                        devem ter um e-mail devidamente
                                        cadastrado no momento do upload na
                                        plataforma. Confira abaixo os e-mails
                                        que já foram enviados a esse titulo:
                                    </p>
                                    <v-timeline
                                        align-top
                                        :dense="$vuetify.breakpoint.smAndDown"
                                        class="ma-3"
                                        data-cy="listaEmails"
                                    >
                                        <v-timeline-item
                                            v-for="item in historicoEmails"
                                            :key="item.id"
                                            icon="mdi-email"
                                            color="grey"
                                            class="mb-5"
                                        >
                                            <v-card color="primary" dark>
                                                <span
                                                    v-if="
                                                        item.cd_status === 220
                                                    "
                                                >
                                                    <v-card-title class="title">
                                                        Solicitado Protesto
                                                    </v-card-title>
                                                    <v-card
                                                        large
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto
                                                            font-weight-black
                                                            ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Solicitado Protesto
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                            white
                                                            text--primary
                                                        "
                                                    >
                                                        <v-btn
                                                            color="primary"
                                                            @click="visualizarEmail(item)"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 225
                                                    "
                                                >
                                                    <v-card
                                                        x-large
                                                        block
                                                        class="
                                                            primary
                                                            font-weight-black
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Apontado
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                            white
                                                            text--primary
                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status ===
                                                            102 &&
                                                            item.lembrete === 1
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            font-weight-black
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Protestado ( Lembrete de
                                                        Protesto )
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                            white
                                                            text--primary
                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status ===
                                                            102 &&
                                                            item.lembrete === 0
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            font-weight-black
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Protestado
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                            white
                                                            text--primary
                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 240
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                            primary
                                                            font-weight-black
                                                            ma-auto ma-3
                                                            pa-3
                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Solicitado Cancelamento
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                        primary
                                                                                        ma-auto ma-3
                                                                                        pa-3
                                                                                    "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                                                                                            white
                                                                                                                            text--primary
                                                                                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 241
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            font-weight-black
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Enviado a Cancelamento
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                                                                                            white
                                                                                                                            text--primary
                                                                                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 250
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            font-weight-black
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Solicitado Anuência
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                                                                                            white
                                                                                                                            text--primary
                                                                                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 251
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            font-weight-black
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Enviado a Anuência
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                                                                                            white
                                                                                                                            text--primary
                                                                                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.cd_status === 252
                                                    "
                                                >
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            font-weight-black
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Anuência programada
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        Data do envio do e-mail:
                                                        {{
                                                            formatDate(
                                                                item.dt_envio
                                                            )
                                                        }}
                                                    </v-card>
                                                    <v-card
                                                        small
                                                        block
                                                        class="
                                                                                                                            primary
                                                                                                                            ma-auto ma-3
                                                                                                                            pa-3
                                                                                                                        "
                                                    >
                                                        <v-icon class=""
                                                            >mdi-information-outline</v-icon
                                                        >
                                                        E-mail Enviado com
                                                        Sucesso!
                                                    </v-card>
                                                    <v-card-text
                                                        class="
                                                                                                                            white
                                                                                                                            text--primary
                                                                                                                        "
                                                    >
                                                        <v-btn
                                                            @click="visualizarEmail(item)"
                                                            color="primary"
                                                            text
                                                            outlined
                                                            >Ver Detalhes</v-btn
                                                        >
                                                    </v-card-text>
                                                </span>
                                            </v-card>
                                        </v-timeline-item>
                                    </v-timeline>
                                </v-card>
                                <v-card
                                    v-show="historicoVazio"
                                    class="mr-3 pr-3"
                                >
                                    <p class="ma-3 pa-3">
                                        <v-icon class=""
                                            >mdi-information-outline</v-icon
                                        >
                                        Nenhum registro encontrado para esse
                                        título
                                    </p>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <HistoricoTitulo
                        class="pa-3"
                        :historico_titulo="historicos"
                    />
                    <v-card
                        v-if="usuarioRecuperi && superUsuario && !usuarioPartner"
                        class="pa-3 ma-3"
                        data-cy="card-comentarios"
                    >
                        <p v-show="habilitaComentarios" class="title">
                            <v-icon class="ma-2">mdi-information-outline</v-icon
                            >Comentários do Título
                        </p>
                        <p v-show="!habilitaComentarios" class="title">
                            <v-icon class="ma-2">mdi-text-search</v-icon
                            >Buscando Comentários
                        </p>
                        <v-progress-linear
                            v-show="!habilitaComentarios"
                            buffer-value="55"
                            color="primary"
                            reverse
                            stream
                            value="30"
                        ></v-progress-linear>
                        <v-card
                            v-for="(item, index) in listaComentarios"
                            :key="index"
                            v-show="habilitaComentarios"
                        >
                            <v-card-text
                                >Comentário criado por:
                                <b>{{ item.email_user }}</b> (id:
                                {{ item.id_user }}/{{ item.name_user }}) -
                                Criado em:
                                {{ formatData(item.dt_criacao) }}</v-card-text
                            >
                            <v-row>
                                <v-col md="2" lg="2">
                                    <v-card-text
                                        ><b>Comentário:</b></v-card-text
                                    >
                                </v-col>
                                <v-col md="10" lg="10">
                                    <v-textarea
                                        readonly
                                        rows="1"
                                        autoGrow
                                        solo
                                        v-model="item.comentario"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                            v-show="habilitaComentarios"
                            class="pa-3 mt-5"
                            color="grey lighten-3"
                        >
                            <p class="title">
                                <v-icon class="ma-2">mdi-plus-box</v-icon>Criar
                                Novo comentário
                            </p>
                            <v-textarea
                                solo
                                rows="3"
                                label="Informe aqui o comentário que deseja inserir no título"
                                v-model="comentario"
                            />
                            <v-row>
                                <v-col align="right" justify="right">
                                    <v-btn
                                        :loading="habilitaLoadingComentarios"
                                        @click="criarComentario"
                                        class="primary white--text"
                                        >Inserir comentário</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card>                    
                    <v-dialog
                        v-model="template220"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template220
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            :saldoProtestoFormatado="
                                titulo.vl_saldo_protesto_formatado
                            "
                            @fecharModal="template220 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template225"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template225
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            @fecharModal="template225 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template102"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template102
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            :saldoProtestoFormatado="
                                titulo.vl_saldo_protesto_formatado
                            "
                            @fecharModal="template102 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template102Lembrete"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template102Lembrete
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            @fecharModal="template102Lembrete = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template240"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template240
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            @fecharModal="template240 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template250"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template250
                            v-if="!isSaoPaulo"
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            @fecharModal="template250 = $event"
                        />
                        <Template250SP
                            v-else
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            @fecharModal="template250 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="template252"
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                    >
                        <Template252
                            :usuarioPartner="usuarioPartner"
                            :nomeDevedor="titulo.nm_devedor"
                            :ds_nosso_numero="titulo.ds_nosso_numero"
                            :nomeExibicaoApresentante="NomeExibicaoApresentante"
                            :saldoProtestoFormatado="
                                titulo.vl_saldo_protesto_formatado
                            "
                            @fecharModal="template252 = $event"
                        />
                    </v-dialog>
                    <v-dialog
                        v-model="dialogInfoDesistencia"
                        persistent
                        transition="dialog-bottom-transition"
                        max-width="1000px"
                        class="ma-3 pa-3"
                    >
                        <v-card>
                            <v-row>
                                <v-col md="7" lg="7" class="pa-5 ma-4">
                                    <v-card-title class="font-weight-black">
                                        <v-icon size="50" class="ma-2"
                                            >mdi-alert-circle-outline</v-icon
                                        >Atenção
                                    </v-card-title>
                                    <v-card-title
                                        class="
                                                                                                            red--text
                                                                                                            display-1
                                                                                                            font-weight-black
                                                                                                        "
                                    >
                                        A desistência foi solicitada após o
                                        prazo legal de 3 dias da data de envio
                                        do título para protesto.
                                    </v-card-title>
                                    <v-card-text class="">
                                        Caso o cartório responsável não aceite o
                                        pedido de desistência (de acordo com a
                                        Lei nº 9.492/1997), a Recuperi enviará
                                        automaticamente o comando de
                                        cancelamento assim que o devedor for
                                        efetivamente protestado.
                                    </v-card-text>
                                    <v-card-text class="">
                                        Importante: O comando de desistência
                                        gera custos de responsabilidade do
                                        apresentante.
                                    </v-card-text>
                                </v-col>
                                <v-col md="4" lg="4" class="pa-5 ma-4">
                                    <v-card-text
                                        >Saiba mais:
                                        <a
                                            href="http://www.planalto.gov.br/ccivil_03/leis/l9492.htm"
                                            target="_blank"
                                        >
                                            http://www.planalto.gov.br/ccivil_03/leis/l9492.htm</a
                                        >
                                    </v-card-text>
                                    <v-card-text>
                                        <v-icon class="ma-2"
                                            >mdi-arrow-right</v-icon
                                        >Em caso de dúvida, por favor entre em
                                        contato com suporte@recuperi.com.br
                                    </v-card-text>
                                    <v-btn
                                        color="green white--text"
                                        class="ma-3 pa-3"
                                        @click="confirmaCancelamentoDesistencia"
                                        >Ok, desejo continuar</v-btn
                                    >

                                    <v-btn
                                        color="red white--text"
                                        class="ma-3 pa-3"
                                        @click="fechaDialogInfoDesistencia"
                                        >Fechar</v-btn
                                    >
                                </v-col>
                            </v-row>
                            <div class="ma-3 pa-3"></div>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="reapresentacaoDialog"
                        persistent
                        transition="dialog-bottom-transition"
                        max-width="1100px"
                    >
                        <reapresentacao-titulo
                            @fecharModal="reapresentacaoDialog = false"
                            :titulo="titulo"
                            @reloadTable="reloadTable"
                            />

                        

                    </v-dialog>
                    <v-dialog
                        v-if="habilitaCorrecao"
                        v-model="habilitaCorrecao"
                        persistent
                        transition="dialog-bottom-transition"
                        max-width="900px"
                    >
                        <correcao-titulo
                            @fecharModal="desabilitaDialogCorrecao"
                            :detalhe_titulo="titulo"
                            :open="computedOpen"
                        ></correcao-titulo>
                    </v-dialog>
                    <v-dialog
                        v-if="habilitaOferta"
                        v-model="habilitaOferta"
                        transition="dialog-bottom-transition"
                        max-width="1200px"
                    >
                        <envia-oferta
                            @fechar="desabilitaDialogOferta"
                            @atualizar="exibirOferta(titulo)"
                            :detalhe_titulo="titulo"
                            :ofertas="ofertas"
                            :open="computedOpen"
                        ></envia-oferta>
                    </v-dialog>
                </v-card-text>
                <v-divider />
                <v-card-actions
                    v-if="!isUserReadonly"
                    class="d-block py-4"
                    style="height: auto;"
                    data-cy="consultaTituloActions"
                >
                    <v-row
                        style="display: flex; justify-content: space-between; padding: 12px 56px 8px 30px">

                        <!--botões comandos esquerda-->
                        <!--somente ações referentes a esteira de protesto-->
                        <div v-if="parseInt(titulo.cd_status) < 300" >
                            <v-btn
                                v-if="
                                    (superUsuario &&
                                        usuarioRecuperi &&
                                        titulo.cd_status === 102) ||
                                        (superUsuario &&
                                            usuarioRecuperi &&
                                            titulo.cd_status === 144)
                                "
                                dense
                                color="green white--text"
                                :disabled="ativaBotaoOferta"
                                @click="exibirOferta(titulo)"
                            >
                                Ofertas
                                <v-icon small class="ml-2">mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="superUsuario && usuarioRecuperi"
                                dense
                                :class="
                                    superUsuario && usuarioRecuperi ? 'ml-3' : ''
                                "
                                v-model="recolocarEmFilaButton"
                                color="green white--text"
                                :disabled="desabilitaBotaoFila || isUserReadonly"
                                @click="recolocarEmFila(titulo)"
                            >
                                Reiniciar Envio
                                <v-icon small class="ml-2">mdi-restart</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="ultimoFiltro?.tem_irr_nao_reapresentado && !usuarioLite"
                                data-cy="btn-reapresentar-titulo"
                                dense
                                :class="
                                    superUsuario && usuarioRecuperi ? 'ml-3' : ''
                                "
                                v-model="recolocarEmFilaButton"
                                color="green white--text"
                                @click="reapresentacaoDialog = true"
                            >
                                Reapresentar
                                <v-icon small class="ml-2">mdi-restart</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="
                                    (superUsuario || usuarioAdmin) &&
                                        verificarCancelarComandoTitulo(titulo)
                                "
                                dense
                                :class="
                                    superUsuario && usuarioRecuperi ? 'ml-3' : ''
                                "
                                color="red white--text"
                                :disabled="isUserReadonly"
                                @click="modalCancelarComando()"
                                data-cy="cancelarComando"
                            >
                                Cancelar Comando
                                <v-icon small class="ml-2">cancel</v-icon>
                            </v-btn>
                        </div>

                        <!--botões comandos direita-->
                        <!--somente ações referentes a esteira de protesto-->
                        <div v-if="parseInt(titulo.cd_status) < 300">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="!usuarioLite && titulo.ds_revisao !== 'AP'"
                                        :disabled="
                                            computedPermitirCorrecao ||
                                                isUserReadonly                                                
                                        "
                                        class="ml-3"
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red white--text"
                                        data-cy="correcaoBtn"
                                        @click="
                                            habilitaCorrecao = !habilitaCorrecao
                                        "
                                    >
                                        Correção
                                        <v-icon small class="ml-2">build</v-icon>
                                    </v-btn>
                                </template>
                                <span>Realizar Correção</span>
                            </v-tooltip>
                            <v-tooltip top width="300" max-width="350">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="ml-3"
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        color="success darken-1"
                                        :disabled="
                                            computedPermitirAnuencia ||
                                                isUserReadonly
                                        "
                                        @click="solicitaAnuencia"
                                        :loading="loadingCancelamento"
                                        data-cy="solicitarAnuencia"
                                    >
                                        Anuência
                                        <v-icon small class="ml-2">check</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Programar pedido de Anuência -
                                </span>
                                <span>
                                    Informação Importante: Apenas os valores
                                    protestados (ou outros valores, conforme
                                    renegociado com o Devedor) foram pagos pelo
                                    Devedor ao Apresentante – Caberá ao Devedor
                                    realizar o pagamento dos emolumentos diretamente
                                    aos cartórios.
                                </span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-show="!usuarioLite"
                                        class="ml-3"
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        color="orange darken-3 white--text"
                                        data-cy="solicitarCancelamento"
                                        :disabled="
                                            computedPermitirCancelamento ||
                                                isUserReadonly
                                        "
                                        @click="solicitaCancelamento"
                                        :loading="loadingCancelamento"
                                    >
                                        Cancelamento
                                        <v-icon small class="ml-2">cancel</v-icon>
                                    </v-btn>
                                </template>
                                <span
                                    >Solicitar pedido de Cancelamento do
                                    Protesto</span
                                >
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-show="!usuarioLite"
                                        class="ml-3"
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        :color="colorDesistencia"
                                        :disabled="
                                            computedPermitirDesistencia ||
                                                isUserReadonly
                                        "
                                        @click="solicitaDesistencia"
                                        :loading="loadingCancelamento"
                                    >
                                        Desistência
                                        <v-icon small class="ml-2">stop</v-icon>
                                    </v-btn>
                                </template>
                                <span
                                    >Solicitar pedido de Desistência do
                                    Protesto</span
                                >
                            </v-tooltip>
                        </div>

                        <div v-if="parseInt(titulo.cd_status) >= 300">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-show="!usuarioLite"
                                        :disabled="![320, 321, 325, 302].includes(titulo.cd_status)"
                                        class="ml-3"
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        color="orange darken-3 white--text"
                                        data-cy="solicitarCancelamento"
                                        
                                        @click="solicitaCancelamentoNegociacao"
                                        :loading="loadingCancelamento"
                                    >
                                        Cancelamento Negociação
                                        <v-icon small class="ml-2">cancel</v-icon>
                                    </v-btn>
                                </template>
                                <span
                                    >Solicitar pedido de Cancelamento do
                                    Negociação</span
                                >
                            </v-tooltip>
                        </div>
                    </v-row>
                    <!-- v-if="superUsuario" -->
                    <v-row align="center" v-if="superUsuario && usuarioRecuperi">
                        <v-divider></v-divider><strong class="px-3"> Ações de usuário Recuperi</strong><v-divider></v-divider>
                    </v-row>
                    <v-row v-if="superUsuario && usuarioRecuperi" data-cy="superAdminSession" style="display: flex; justify-content: end; padding: 8px 56px 8px 42px">

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="statusParaRenegociarProtestar.includes(titulo.cd_status)"
                                    class="ml-3"
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="colorDesistencia"
                                    @click="solicitarAleracaoStatus(320)"
                                >
                                    Enviar a Negociação
                                    <v-icon small class="ml-2">mdi-play</v-icon>
                                </v-btn>
                            </template>
                            <span>Enviar titulo para negociação</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="statusParaRenegociarProtestar.includes(titulo.cd_status)"
                                    class="ml-3"
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="colorDesistencia"
                                    @click="solicitarAleracaoStatus(220)"
                                >
                                    Enviar a Protesto
                                    <v-icon small class="ml-2">mdi-play</v-icon>
                                </v-btn>
                            </template>
                            <span>Enviar titulo para protesto</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="statusParaIrregularizarTitulo.includes(titulo.cd_status)"
                                    class="ml-3"
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="colorDesistencia"
                                    @click="solicitarAleracaoStatus(105,'K1')"
                                >
                                    105-K1
                                    <v-icon small class="ml-2">stop</v-icon>
                                </v-btn>
                            </template>
                            <span>Irregularizar o título devido a comarca estar inválida</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="statusParaIrregularizarTituloK3.includes(titulo.cd_status)"
                                    class="ml-3"
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="colorDesistencia"
                                    @click="solicitarAleracaoStatus(105,'K3')"
                                >
                                    105-K3
                                    <v-icon small class="ml-2">stop</v-icon>
                                </v-btn>
                            </template>
                            <span>Irregularizar o título devido a erro do cartório</span>
                        </v-tooltip>
                        <v-tooltip top v-if="statusFinalizadores.includes(titulo.cd_status)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="usuarioRecuperi"
                                    dense
                                    v-on="on"
                                    v-bind="attrs"
                                    :class="usuarioRecuperi ? 'ml-3' : ''"
                                    color="blue darken-3 white--text"
                                    @click="desativarMailing()"
                                >
                                    {{titulo.st_email_callback === 1 ? 'Desativar Mailing' : 'Ativar Mailing'}}
                                    <v-icon small class="ml-2">cancel</v-icon>
                                </v-btn>
                            </template>
                            <span>Desativar Mailing para este título, outros títulos para este e-mail não é alterado</span>
                        </v-tooltip>
                        <v-tooltip top v-if="statusFinalizadores.includes(titulo.cd_status)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dense
                                    v-on="on"
                                    v-bind="attrs"
                                    :class="usuarioRecuperi ? 'ml-3' : ''"
                                    color="blue darken-3 white--text"
                                    @click="openModalTrocarContatoDevedor()"
                                >
                                    Alterar Contatos Devedor
                                    
                                </v-btn>
                            </template>
                            <span>Alterar contatos de e-mail e telefone de devedor</span>
                        </v-tooltip>
                        
                    </v-row>
                </v-card-actions>
            </v-card>
            <!-- fim das informações sobre o titulo--------------------- -->
        </v-dialog>
        <!-- fim do Dialog Geral ------------------------------ -->

        <!-- dialog de carregamento -->
        <v-dialog v-model="modalTrocarContatoDevedor" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text pl-0"
                        >Alterar contatos do devedor</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-row class="mt-3">
                        <v-col>
                            <v-text-field
                                outlined
                                dense
                                v-model="novosContatosdevedor.email"
                                label="E-mail do devedor"
                                required
                            ></v-text-field>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-mask="'(##) #####-####'"
                                outlined
                                dense
                                v-model="novosContatosdevedor.celular"
                                label="Telefone do devedor"
                                required
                            ></v-text-field>

                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        dense
                        color="red white--text"
                        text
                        @click="modalTrocarContatoDevedor = false"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        dense
                        color="green darken-1"
                        text
                        @click="trocarContatoDevedor()"
                        >Salvar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="dialogLoading"
            width="450px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-progress-circular
                        class="ma-12"
                        indeterminate
                        size="150"
                        color="primary"
                        >Carregando...
                    </v-progress-circular>
                </v-card>
            </div>
        </v-dialog>
        <!-- fim do dialog de carregamento -->

        <v-dialog v-model="mostraConfirmacaoRelatorioEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        data-cy="btnSubmitReportConfirmation"
                        color="green darken-1"
                        text
                        v-show="mostraConfirmacaoRelatorioEmail"
                        @click="mostraConfirmacaoRelatorioEmail = false"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="mostraConfirmacaoRelatorioEmailDetalhado"
            max-width="500"
        >
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        data-cy="btnSubmitReportConfirmationCsv"
                        text
                        v-show="mostraConfirmacaoRelatorioEmailDetalhado"
                        @click="
                            mostraConfirmacaoRelatorioEmailDetalhado = false
                        "
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mostraConfirmacaoPdf" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        v-show="mostraConfirmacaoPdf"
                        @click="mostraConfirmacaoPdf = false"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Pegar e-mail report -->
        <v-dialog v-model="modelRelatorioXlsEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-form
                        ref="formEmail"
                        v-model="validEmail"
                        @keyup.native.enter="checkformSubmit()"
                    >
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="E-mail"
                            type="email"
                            v-model="emailReport"
                            :rules="emailRules"
                            required
                            data-cy="emailFieldforReport"
                        >
                        </v-text-field>
                    </v-form>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarEmailSQS()"
                        :disabled="checkForm()"
                        data-cy="btnSubmitReport"
                    >
                        Enviar
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modelRelatorioDetalhado" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-form ref="formEmail" v-model="validEmail">
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="E-mail"
                            type="email"
                            v-model="emailReport"
                            :rules="emailRules"
                            required
                            data-cy="emailFieldforReportCsv"
                        >
                        </v-text-field>
                    </v-form>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarEmailDetalhado()"
                        :disabled="checkForm()"
                        data-cy="submitReportCsv"
                    >
                        Enviar
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modelListaPdfSimples" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-form ref="formEmail" v-model="validEmail">
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="E-mail"
                            type="email"
                            v-model="emailReport"
                            :rules="emailRules"
                            required
                            data-cy="emailFieldforReportPDF"
                        >
                        </v-text-field>
                    </v-form>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarEmailPdfSimples()"
                        :disabled="checkForm()"
                        data-cy="submitReportPDF"
                    >
                        Enviar
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-btn class="grey lighten-4" x-small fab icon
                        ><v-icon class="primary--text lighten-2 pa-2">
                            search
                        </v-icon></v-btn
                    >
                    Títulos > Consulta de Títulos
                </v-card-text>
            </v-card>
            <!-- Cabeçalho -->
            <v-card @keyup.native.enter="botaoFiltrar" class="my-2">
                <v-row align="center" class="mx-3 mt-3 mb-0">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-textarea
                            auto-grow
                            solo
                            small
                            v-bind:style="{
                                maxHeight: '15rem',
                                overflowY: 'auto'
                            }"
                            rows="1"
                            label="Nosso número"
                            hint="Informe o nosso número"
                            placeholder="Ex.:477977"
                            data-cy="filtroNossoNumero"
                            class="mt-5"
                            v-model="filtros.ds_nosso_numero"
                        ></v-textarea>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey
                                                                                                "
                                        v-bind="attrs"
                                        v-on="on"
                                        class=""
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Informe o Número do Título</span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row align-center"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="CPF/CNPJ"
                            hint="Informe o Número do CPF/CNPJ"
                            placeholder="Ex.:000.000.000-00"
                            v-mask="['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                            v-model="filtros.nu_identificacao_devedor"
                            clearable
                            data-cy="filtroCpfCnpj"
                        >
                        </v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        class="grey--text"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Informe o CPF/CNPJ, ou apenas seus números
                                iniciais</span
                            >
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-text-field
                            solo
                            small
                            class="mt-5"
                            label="Nome"
                            hint="Informe o nome para efetuar a busca"
                            placeholder="Ex.: José"
                            clearable
                            v-model="filtros.nm_devedor"
                            data-cy="nomeDevedor"
                        ></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Informe o Nome que deseja encontrar, por
                                exemplo: Ricardo</span
                            >
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-autocomplete
                            :loading="loadingInicial"
                            solo
                            @input="verificaStatus"
                            multiple
                            small
                            class="mt-5"
                            v-model="filtros.cd_status"
                            :items="listaSituacoes"
                            label="Status"
                            item-text="nm_status"
                            item-value="cd_status"
                            clearable
                            counter="6"
                            hint="Selecione até 6 status para filtrar"
                            persistent-hint
                            data-cy="statusTitulo"
                        ></v-autocomplete>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="ma-auto ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Selecione um tipo de status desejado</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row align="center" class="mx-3 mt-1 mb-0">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-menu
                            v-model="menuDataCadastroRange"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    solo
                                    small
                                    v-model="computedDateCadastroFormattedRange"
                                    label="Data de cadastro"
                                    hint="Data de cadastro: inícial e final"
                                    placeholder="Selecione no botão ao lado"
                                    clearable
                                    @change="limparDataCadastro"
                                ></v-text-field>
                                <v-btn
                                    fab
                                    x-small
                                    elevation="0"
                                    class="grey--text mt-3 ml-2"
                                >
                                    <v-icon class="" v-on="on">
                                        mdi-calendar
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-date-picker
                                v-model="filtros.dt_cadastro_range"
                                range
                                color="primary"
                                @input="
                                    validarSelecaoData(
                                        $event,
                                        'menuDataCadastroRange'
                                    )
                                "
                                locale="pt-br"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="d-flex flex-row"
                    >
                        <v-menu
                            v-model="menuDataMovimentoRange"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    solo
                                    v-model="
                                        computedDateMovimentoFormattedRange
                                    "
                                    label="Data de movimentação"
                                    placeholder="Selecione no botão ao lado"
                                    hint="Data Movimento: Inicial e Final"
                                    clearable
                                    @change="limparDataMovimento"
                                ></v-text-field>
                                <v-btn
                                    fab
                                    x-small
                                    elevation="0"
                                    class="grey--text mt-3 ml-2"
                                    data-cy="dataMovimentoFilter"
                                >
                                    <v-icon class="" v-on="on">
                                        mdi-calendar
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-date-picker
                                v-model="filtros.dt_arquivo_movimento_range"
                                range
                                color="primary"
                                @input="
                                    validarSelecaoData(
                                        $event,
                                        'menuDataMovimentoRange'
                                    )
                                "
                                locale="pt-br"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="pa-3 d-flex justify-start align-center"
                    >
                        <v-select
                            solo
                            small
                            v-model="filtros.cd_uf_devedor"
                            :items="listaUfs"
                            label="UF"
                            clearable
                            :return-object="true"
                        ></v-select>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="mb-5 ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey
                                                                "
                                        v-bind="attrs"
                                        v-on="on"
                                        class=""
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Selecione uma UF</span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="pa-3 d-flex justify-start align-center"
                    >
                    <v-autocomplete
                        solo
                        v-model="filtros.cd_carteira"
                        :items="wallets"
                        :search-input.sync="searchCarteira"
                        label="Buscar carteira"
                        @update:search-input="updateSearch"
                    ></v-autocomplete>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="mb-5 ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Informe o nome da carteira que deseja encontrar. No mínimo, três caracteres. por
                                exemplo: ABC</span
                            >
                        </v-tooltip>                        
                    </v-col>
                </v-row>
                <v-row align="center" class="mx-3 mt-0 mb-3">
                    <v-col
                        v-if="!isUsuarioAgrupador"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="pa-3 d-flex justify-start align-center"
                    >
                        <v-select
                            v-if="!usuarioLite && !usuarioLiteRO"
                            solo
                            dense
                            :disabled="
                                loadingAgrupador || usuarioAgrupador != null
                            "
                            :loading="loadingAgrupador"
                            :items="agrupadores"
                            v-model="filtros.ds_agrupador"
                            label="Agrupador"
                            item-text="nm_agrupador"
                            item-value="ds_agrupador"
                            data-cy="agrupador"
                        />
                        <v-tooltip bottom v-if="!usuarioLite && !usuarioLiteRO">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="mb-5 ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Selecione o agrupador que deseja filtrar</span
                            >
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="8"
                        lg="8"
                        class="pa-3 mb-4 d-flex align-center"
                    >
                        <v-row>

                            <div v-if="!usuarioLite" class="ml-3">
                                <v-switch
                                    v-model="revisao"
                                    hide-details
                                    small
                                    class="mt-n1 d-flex justify-center"
                                    color="primary"
                                    label="Revisão"
                                ></v-switch>
                            </div>
                            <div class="ml-3">
                                <v-switch
                                    v-model="irregulares"
                                    hide-details
                                    small
                                    class="mt-n1 d-flex justify-center"
                                    label="Não mostrar Irregulares"
                                ></v-switch>
                            </div>
                            <div class="ml-3">
                                <v-switch
                                    v-model="comInstrumento"
                                    hide-details
                                    small
                                    class="mt-n1 d-flex justify-center"
                                    label="Com Instrumento de Protesto"
                                ></v-switch>
                            </div>
                            <div class="ml-3">
                                <v-switch
                                    v-model="ignorar_cancelados"
                                    hide-details
                                    small
                                    class="mt-n1 d-flex justify-center"
                                    label="Apenas títulos enviados ou na fila"
                                ></v-switch>
                            </div>
                        </v-row>
                        
                    </v-col>
                </v-row>
                <v-row align="center" class="mx-3 my-0">
                    <v-col
                        v-if="!isUsuarioAgrupador"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        class="pa-3 d-flex justify-start align-center"
                    >
                        <v-select
                            solo
                            dense
                            :disabled="loadingIrregularidades"
                            :loading="loadingIrregularidades"
                            :items="listaIrregularidades"
                            v-model="filtros.cd_irregularidade"
                            label="Irregularidade"
                            item-text="nm_irregularidade"
                            item-value="cd_irregularidade"
                            data-cy="irregularidadeApresentante"
                        />
                        <v-tooltip bottom v-if="!filtros.cd_irregularidade">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    x-small
                                    class="mb-5 ml-2"
                                    elevation="0"
                                >
                                    <v-icon
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span
                                >Selecione a Irregularidade que deseja
                                filtrar</span
                            >
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="9"
                        lg="9"
                        class="pa-3 mb-4 d-flex align-center"
                    >
                        <div>
                            <v-switch
                                v-model="irregularesNaoReapresentados"
                                data-cy="switchIrregularesNaoReapresentados"
                                hide-details
                                small
                                class="mt-n1 d-flex justify-center"
                                label="Irregulares não reapresentados"
                            ></v-switch>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mx-3 mt-0 mb-0">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        class="px-3 pt-0 pb-3"
                        align="left"
                    >
                        <div
                            class="font-weight-bold text-h6"
                            style="color: #6c6c6c"
                        >
                            Data de atualização do Título
                        </div>
                        <div class="font-weight-normal" style="color: #6c6c6c">
                            Pesquise por títulos atualizados dentro do prazo (em
                            dias) selecionado.
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="5"
                        lg="5"
                        align="center"
                        class="d-flex flex-row justify-center align-end px-3 pt-0 pb-3"
                    >
                        <v-btn
                            class="ma-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(1)"
                        >
                            Até 1D
                        </v-btn>
                        <v-btn
                            class="ma-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(7)"
                        >
                            Até 7D
                        </v-btn>
                        <v-btn
                            v-if="!isMobile"
                            class="ma-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(30)"
                        >
                            Até 30D
                        </v-btn>
                        <v-btn
                            v-if="!isMobile"
                            class="my-1 mr-0 ml-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(90)"
                        >
                            Até 90D
                        </v-btn>
                    </v-col>
                    <v-col
                        v-if="isMobile"
                        cols="12"
                        xs="12"
                        sm="12"
                        md="5"
                        lg="5"
                        align="center"
                        class="d-flex flex-row justify-center align-end px-3 pt-0 pb-3"
                        >
                        <v-btn
                            class="ma-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(30)"
                        >
                            Até 30D
                        </v-btn>
                        <v-btn
                            class="my-1 mr-0 ml-1"
                            color="primary"
                            @click="setDataMovimentoFiltro(90)"
                        >
                            Até 90D
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-3 mt-2 mb-0 pb-1 justify-space-between">
                    <!-- <v-spacer /> -->
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="8"
                        lg="8"
                        class="d-flex flex-column justify-start"
                    >
                        <div class="text-start">
                            <v-btn
                                medium
                                color="primary"
                                data-cy="exportarPdf"
                                :disabled="exportActive"
                                :loading="loadingExport"
                                @click="relatorioPdfSimples()"
                            >
                                PDF<v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                            <span class="ml-2">Exportar arquivo PDF Simples (lista) com os resultados</span>
                        </div>
                        <div class="mt-3 text-start">
                            <v-btn
                                medium
                                color="primary"
                                data-cy="exportarXls"
                                :disabled="exportActive"
                                :loading="loadingExport"
                                @click="exportarXls()"
                            >
                                XLS<v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                            <span class="ml-2">Exportar arquivo em formato XLS (lista) com os resultados. Este arquivo pode ser usado para o upload de títulos. {{
                                    totalTitulos > 100000 ? 'via email' : ''
                                }}
                            </span>
                        </div>
                        <div class="mt-3 text-start">
                            <v-btn
                                medium
                                color="primary"
                                :disabled="exportActive"
                                :loading="loadingExport"
                                data-cy="btnExportarCsv"
                                @click="relatorioDetalhes()"
                            >
                                CSV<v-icon class="">mdi-file-table</v-icon>
                            </v-btn>
                            <span class="ml-2"
                                >Exportar relatório Detalhado em formato CSV
                                {{
                                    totalTitulos > 100000 ? 'via email' : ''
                                }}</span
                            >
                        </div>

                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        align="center"
                        class="d-flex flex-column justify-end"
                    >
                        <v-btn
                            block
                            class="mb-3"
                            color="primary"
                            :loading="loadingFiltros"
                            @click="botaoFiltrar"
                            data-cy="filtrarTitulos"
                        >
                            Filtrar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                        <v-btn
                            block
                            class="mt-3"
                            :loading="loadingFiltros"
                            @click="limparFiltros"
                            
                        >
                            Limpar Filtros
                            <v-icon class="ml-2">mdi-filter-remove-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>                
            </v-card>
            <!-- fim do cabeçalho -->            
            <!-- snackbar informativo consulta titulos -->
            <Snackbar
                v-if="consultaSnackbarDialog"
                :mostrarSnackbar="consultaSnackbarDialog"
                
                :corSnackbar="consultaSnackbarColor"
                :mensagemSnackbar="mensagemRetornoconsultaSnackbar"
                @fecharSnackbar="consultaSnackbarDialog = false"
            />
            <!-- fim snackbar informativo -->
            <!-- Tabela com os titulos -->
            <v-data-table
                :headers="headers"
                :items="titulos"
                calculate-widths
                :header-props="headerProps"
                :options.sync="options"
                :server-items-length="totalTitulos"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500],
                    itemsPerPageText: 'Itens por página:'
                }"
                loading-text="Pesquisando títulos..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum título encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id_titulo`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                    >
                        {{ item.id_titulo }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.id_titulo
                    }}</span>
                </template>
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <!---<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.ds_numero_titulo }}</span>--->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.ds_nosso_numero }}</span
                    >
                    <span v-else :style="{ color: 'black' }"
                        >{{ item.ds_nosso_numero }}
                    </span>
                </template>
                <template v-slot:[`item.dt_cadastro`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_cadastro }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.dt_cadastro }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.dt_cadastro
                    }}</span>
                </template>
                <template v-slot:[`item.dt_vencimento`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ formatDataVencimento(item) }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDataVencimento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDataVencimento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ formatDocumento(item) }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDocumento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.nm_devedor }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.nm_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.vl_saldo_protesto`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.vl_saldo_protesto }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.vl_saldo_protesto }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.vl_saldo_protesto
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ getDescSituacao(item) }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ getDescSituacao(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
                <template v-slot:[`item.dt_arquivo_movimento`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.dt_atualizacao }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{
                            item.dt_arquivo_movimento
                                ? convertData(item.dt_arquivo_movimento)
                                : item.dt_atualizacao
                                ? item.dt_atualizacao
                                : '--'
                        }}</span
                    >
                    <!-- ((item.dt_arquivo_movimento.split(" ")[0]).split("-")[0]) -->
                    <span v-else :style="{ color: 'black' }">{{
                        item.dt_arquivo_movimento
                            ? convertData(item.dt_arquivo_movimento)
                            : item.dt_atualizacao
                            ? item.dt_atualizacao
                            : '--'
                    }}</span>
                </template>
                <template v-slot:[`item.cd_uf_devedor`]="{ item }">
                    <!--<span :style="{'color': (item.ds_revisao != '' ? 'red' : 'black' )}">{{ item.cd_uf_devedor }}</span>-->
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.cd_uf_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.cd_uf_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.id_anexo_instrumento`]="{ item }">
                    <span :style="{ color: 'black' }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    dark
                                    small
                                    class="white--text"
                                    @click="verificarFuncaoInstrumentos(item)"
                                    :style="
                                        retornarValorInstrumentoProtesto(
                                            item,
                                            'style'
                                        )
                                    "
                                >
                                    <v-icon dark>
                                        {{
                                            retornarValorInstrumentoProtesto(
                                                item,
                                                'icon'
                                            )
                                        }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{
                                    retornarValorInstrumentoProtesto(
                                        item,
                                        'tooltip'
                                    )
                                }}
                            </span>
                        </v-tooltip>
                    </span>
                </template>
                <template v-slot:[`item.oferta`]="{ item }">
                    <v-icon
                        :style="{ color: item.oferta > 0 ? 'green' : 'red' }"
                    >
                        {{ getBolean(item.oferta) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.cd_comprador`]="{ item }">
                    {{
                        item.cd_comprador === null
                            ? ''
                            : `Original: ${item.cd_apresentante}`
                    }}
                </template>
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        v-if="isVersionBeta"
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="$router.push({ name: 'detalhe-titulo', query: { id_titulo: props.item.id_titulo, cd_apresentante:  cd_apresentante } })"
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            class="mr-2"
                            @click="$router.push({ name: 'detalhe-titulo', params: { id_titulo: props.item.id_titulo, cd_apresentante:  cd_apresentante} })"
                            :style="{ color: 'white' }"
                            >edit
                        </v-icon>
                        Detalhes
                    </v-btn>
                    <v-btn
                        v-else-if="
                            props.item.ds_revisao != '' &&
                                props.item.cd_status == 99999
                        "
                        title="Este título precisa ser revisado. "
                        elevation="1"
                        class="mr-2"
                        width="100%"
                        @click="exibirDetalhe(props.item)"
                        :style="{ color: 'red' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            title="Este título precisa ser revisado. "
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'red' }"
                            >edit</v-icon
                        >
                        Revisar
                    </v-btn>
                    <v-btn
                        v-else
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="exibirDetalhe(props.item)"
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit
                        </v-icon>
                        Detalhes
                    </v-btn>
                </template>
            </v-data-table>
            <Snackbar
                v-if="snackbarDialog"
                :mostrarSnackbar="snackbarDialog"
                :timeoutSnackbar="snackbarTimeout"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemRetornoSnackbar"
                @fecharSnackbar="snackbarDialog = false"
            /> 
            <v-dialog
                v-model="showEmailDialog"
                transition="dialog-bottom-transition"
                max-width="1024px"
            >
                <span v-html="emailContent"></span>
            </v-dialog>
            <!-- fim da tabela com os titulos -->
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Template220 from '@/components/Template220';
import Template225 from '@/components/Template225';
import Template102 from '@/components/Template102';
import Template102Lembrete from '@/components/Template102Lembrete';
import Template240 from '@/components/Template240';
import Template250 from '@/components/Template250';
import Template250SP from '@/components/Template250SP';
import Template252 from '@/components/Template252';
import DetalheTitulo from '@/components/DetalheTitulo';
import ReapresentacaoTitulo from '@/components/organisms/ReapresentacaoTitulo.vue';
import CorrecaoTitulo from '@/components/CorrecaoTitulo';
import HistoricoTitulo from '@/components/HistoricoTitulo';
import CancelamentoService from '@/services/cancelamentoService';
import EmailService from '@/services/EmailService';
import EnviaOferta from '@/components/OfertaTitulo';
import Titulo from '@/domains/Titulo';
import TituloService from '@/services/TituloService';
import * as Formatter from '@/helpers/Formatter';
import * as Utilitarios from '@/helpers/Utilitarios';
import { cloneDeep } from 'lodash';
import {
    getOnlyNumbers,
    getDataHoraString,
    getDataAtualDatePicker,
    getDataDatePicker,
    getDataHoraSubtraida,
    listarAgrupadores,
    listarIrregularidades
} from '@/helpers/Utilitarios';
import lodash from 'lodash';
import 'vue-loading-overlay/dist/vue-loading.css';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import OfertaService from '@/services/OfertaService';
import ApresentanteService from '@/services/apresentanteService';
import { mapGetters } from 'vuex';

export default {
    name: 'ConsultaTitulo',
    components: {
        DetalheTitulo,
        HistoricoTitulo,
        CorrecaoTitulo,
        EnviaOferta,
        Template220,
        Template225,
        Template102,
        Template102Lembrete,
        Template240,
        Template250,
        Template250SP,
        Template252,
        ConfirmacaoModal,
        ReapresentacaoTitulo
    },
    data() {
        return {
            headerProps: {
                sortByText: "Ordenar por",
            },
            novosContatosdevedor: {
                email: null,
                celular: null
            },
            statusFinalizadores: [220,221,225,102,230,231,240,241,250,251,252],
            statusParaIrregularizarTitulo: [220,221,225],
            statusParaIrregularizarTituloK3: [220,221,225,230,252,250],
            statusParaRenegociarProtestar: [304,305,303],
            irregularidadeTipo:'',
            statusTitulo: '',
            modalTrocarContatoDevedor: false,
            reapresentacaoDialog: false,
            consultaSnackbarColor: null,
            consultaSnackbarTimeout: '5000',
            consultaSnackbarDialog: false,
            mensagemRetornoconsultaSnackbar: null,
            snackbarDialog: false,
            snackbarColor: 'grey',
            mensagemSnackBar: '',
            snackbarIcon: 'mdi-info',
            snackbarTimeout: '-1',
            checkbox: false,
            habilitaLoadingComentarios: false,
            habilitaComentarios: false,
            comentario: null,
            listaComentarios: [],
            tituloService: new TituloService(Vue.http, this.$store),
            apresentanteService: new ApresentanteService(Vue.http, this.$store),
            apresentanteProtestado: null,
            ofertaGlobalLoading: false,
            ofertaTituloLoading: false,
            mostrarConfirmacao: false,
            tituloModalConfirmacao: '',
            textoModalConfirmacao: '',
            btnConfirmarModalConfirmacao: '',
            setAtualizarTitulo: false,
            isSaoPaulo: false,
            modalInformativoPreAnuencia: false,
            usuarioLite: false,
            usuarioBasic: false,
            usuarioAdmin: false,
            alertType: null,
            alertColor: null,
            dialogInfoDesistencia: false,
            desabilitaBotaoFila: true,
            permissao: true,
            dt_ontem: null,
            dt_ontem_desistencia: null,
            superUsuario: null,
            mensagemRetorno: null,
            mensagemRetornoSnackbar: null,
            recolocarEmFilaButton: null,
            snackbarLateral: true,
            counter: 1,
            menuDataVencimentoRange: false,
            validEmail: false,
            historicoCheck: false,
            historicoLembretes: null,
            logo: require('@/assets/logo_recuperi_azul_escura.png'),
            historicoVazio: false,
            template220: false,
            template225: false,
            template102: false,
            template240: false,
            template250: false,
            template252: false,
            NomeExibicaoApresentante: null,
            template102Lembrete: false,
            menuDataCadastroRange: false,
            menuDataMovimentoRange: false,
            revisao: false,
            oferta: false,
            emailReport: null,
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            dialogLoading: false,
            filtrar: false,
            loadingCancelamento: false,
            tipo_path: 'cancelamento',
            tipo_cancelamento: 'o Cancelamento',
            dialogText: null,
            dialogConfirmation: false,
            dialogResponse: false,
            dialog: false,
            historicos: [],
            historicoEmails: [],
            historicoListaTratados: null,
            cd_apresentante: this.$store.getters.cdApresentante,
            ultimoFiltro: null,
            exportActive: true,
            registros_pagina: 10,
            numero_paginas: 1,
            showPagination: false,
            total_paginas_visible: 10,
            loadingFiltros: false,
            loadingTituloImpressao: false,
            loadingRelatorioPDF: false,
            usuarioPartner: false,
            usuarioRecuperi: false,
            loadingExport: false,
            loadingRelatorioDetalhes: false,
            modelRelatorioXlsEmail: false,
            modelRelatorioDetalhado: false,
            mostraConfirmacaoRelatorioEmail: false,
            mostraConfirmacaoRelatorioEmailDetalhado: false,
            mostraConfirmacaoPdf: false,
            modelListaPdfSimples: false,
            situacoesTitulo: [],
            totalTitulos: 0,
            titulos: [],
            titulo: new Titulo(),
            ultimoTitulo: [],
            loading: false,
            loadingInicial: false,
            irregulares: false,
            comInstrumento: false,
            menuDataDe: false,
            menuDataAte: false,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            boleto: null,
            mensagem: false,
            habilitaCorrecao: false,
            habilitaOferta: false,
            id_titulo_detalhe: null,
            dialogExtra: null,
            labelConfirm: null,
            ofertas: null,
            ofertasGlobais: [],
            listaUfs: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            ignorar_cancelados: true,
            filtros: {
                ignorar_cancelados: 1,
                tem_irr_nao_reapresentado: null,
                cd_irregularidade: null,
                ds_revisao: null,
                irregulares: null,
                cd_status: null,
                nm_devedor: null,
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                ds_agrupador: null,
                apontamento_nu_protocolo: null,
                dt_vencimento_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_cadastro_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)/
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_arquivo_movimento_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_vencimento_range_fmt: null,
                dt_arquivo_movimento_range_fmt: null,
                dt_cadastro_range_fmt: null
            },
            headers: [
                {
                    text: 'Número do título',
                    sortable: true,
                    value: 'ds_nosso_numero',
                    width: '15%'
                },
                {
                    text: 'Data de cadastro',
                    align: 'left',
                    sortable: true,
                    value: 'dt_cadastro',
                    width: '15%'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor',
                    width: '15%'
                },
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'nm_devedor',
                    width: '15%'
                },
                {
                    text: 'Valor R$',
                    sortable: true,
                    value: 'vl_saldo_protesto',
                    width: '15%'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'cd_status',
                    width: '15%'
                },
                {
                    text: 'Data de Movimento',
                    align: 'left',
                    sortable: true,
                    value: 'dt_arquivo_movimento',
                    width: '15%'
                },
                {
                    text: 'Instrumento de Protesto',
                    align: 'center',
                    sortable: false,
                    value: 'id_anexo_instrumento',
                    width: '15%'
                },
                {
                    text: 'UF',
                    sortable: false,
                    value: 'cd_uf_devedor',
                    width: '15%'
                },
                {
                    text: 'Original',
                    sortable: false,
                    value: 'cd_comprador',
                    width: '15%'
                },
                // { text: 'Renegociação', sortable: true, value: 'oferta' },
                { text: ' ', sortable: false, value: 'action', width: '15%' }
            ],
            loadingAgrupador: false,
            loadingIrregularidades: false,
            irregularesNaoReapresentados: false,
            searchCarteira: null,
            wallets: [],
            dialogConfirmationGeneric: false,
            genericDialog: {
                title: '',
                text: '',
                labelConfirmBtn: '',
                labelCancelBtn: ''
            },
            usuarioLiteRO: false,
            showEmailDialog: false,
            emailContent: '',
            isMobile: false,
            dialogCancelamentoNegociacao: false
        };
    },
    watch: {
        searchCarteira(val) {
            if (val) {
                this.fetchWallets(val);
            }
        },
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        },
        'filtros.dt_vencimento_range'() {
            this.filtros.dt_vencimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_vencimento_range[0]),
                Formatter.formatData(this.filtros.dt_vencimento_range[1])
            ];
            if (
                this.filtros.dt_vencimento_range[0] <
                    this.filtros.dt_vencimento_range[1] ||
                this.filtros.dt_vencimento_range[1] === undefined
            ) {
                this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
                this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];
            } else {
                this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[1];
                this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[0];
            }
        },
        //'filtros.dt_vencimento_de'() {
        //   this.filtros.dt_vencimento_de_fmt = Formatter.formatData(
        //     this.filtros.dt_vencimento_de
        //);
        //},
        //'filtros.dt_vencimento_ate'() {
        //  this.filtros.dt_vencimento_ate_fmt = Formatter.formatData(
        //    this.filtros.dt_vencimento_ate
        //);
        //},
        'filtros.dt_cadastro_range'() {
            this.filtros.dt_cadastro_range_fmt = [
                Formatter.formatData(this.filtros.dt_cadastro_range[0]),
                Formatter.formatData(this.filtros.dt_cadastro_range[1])
            ];
            if (
                this.filtros.dt_cadastro_range[0] <
                    this.filtros.dt_cadastro_range[1] ||
                this.filtros.dt_cadastro_range[1] === undefined
            ) {
                this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
                this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];
            } else {
                this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[1];
                this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[0];
            }
        },
        'filtros.dt_arquivo_movimento_range'() {
            this.filtros.dt_arquivo_movimento_range_fmt = [
                Formatter.formatData(
                    this.filtros.dt_arquivo_movimento_range[0]
                ),
                Formatter.formatData(this.filtros.dt_arquivo_movimento_range[1])
            ];
            if (
                this.filtros.dt_arquivo_movimento_range[0] <
                    this.filtros.dt_arquivo_movimento_range[1] ||
                this.filtros.dt_arquivo_movimento_range[1] === undefined
            ) {
                this.filtros.dt_arquivo_movimento_de = this.filtros.dt_arquivo_movimento_range[0];
                this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_arquivo_movimento_range[1];
            } else {
                this.filtros.dt_arquivo_movimento_de = this.filtros.dt_arquivo_movimento_range[1];
                this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_arquivo_movimento_range[0];
            }
        }
    },
    computed: {
        ...mapGetters(['listaIrregularidades']),
        ativaBotaoOferta() {
            let data;
            this.apresentanteProtestado == null
                ? (data = true)
                : (data = false);
            return data;
        },
        isVersionBeta() {
            return this.$store.getters.isBetaVersion;
        },
        computedGetUserName() {
            return this.$store.getters.nomeApresentante;
        },
        computedOpen() {
            this.somaCounter();
            return this.counter;
        },
        computedDateVencimentoFormattedRange: {
            get: function() {
                return this.dataVencimento();
            },
            set: function(value) {
                this.menuDataVencimentoRange = value;
            }
        },
        computedDateCadastroFormattedRange: {
            get: function() {
                return this.dataCadastro();
            },
            set: function(value) {
                this.menuDataCadastroRange = value;
            }
        },
        computedDateMovimentoFormattedRange: {
            get: function() {
                return this.dataMovimentacao();
            },
            set: function(value) {
                this.menuDataMovimentoRange = value;
            }
        },
        computedPermitirCorrecao() {
            if (
                this.titulo.ds_revisao != '[QD]' &&
                this.titulo.ds_revisao != null &&
                this.titulo.ds_revisao != '' &&
                this.titulo.cd_status == 220
            ) {
                return false;
            } else {
                return true;
            }
        },
        computedPermitirAnuencia() {
            if ([123, 109, 102, 221, 225].includes(this.titulo.cd_status)) {
                if (
                    this.historicos[0] &&
                    [250].includes(this.historicos[0].cd_status)
                ) {
                    // se já existir um histórico 250, não permitir nova anuência
                    return true;
                }
                if (!this.historicos[0]) {
                    // se não há histórico, permitir anuência
                    return false;
                } else return false;
            } else return true;
        },
        computedPermitirCancelamento() {
            if (
                [123, 109, 102, 250, 251, 144].includes(this.titulo.cd_status)
            ) {
                if (
                    this.historicos[0] &&
                    [250].includes(this.historicos[0].cd_status)
                ) {
                    return true;
                }
                if (!this.historicos[0]) {
                    // se não há histórico, permitir comando
                    return false;
                } else return false;
            } else return true;
        },
        computedPermitirDesistencia() {
            if (
                [220, 221, 225].includes(this.titulo.cd_status) ||
                this.titulo.permite_desistencia
            ) {
                if (
                    this.historicos[0] &&
                    [250].includes(this.historicos[0].cd_status)
                ) {
                    return true;
                }
                if (!this.historicos[0]) {
                    // se não há histórico, permitir comando
                    return false;
                } else return false;
            } else return true;
        },
        colorDesistencia() {
            // if (this.titulo.permite_desistencia_atrasada) {
            //     return 'yellow darken-3 white--text';
            // }
            return 'blue darken-3 white--text';
        },
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        // listaCarteiras() {
        //     return this.$store.getters.Carteiras;
        // },
        computedDateFormattedDe() {
            return Formatter.formatData(this.filtros.dt_vencimento_de);
        },
        computedDateFormattedAte() {
            return Formatter.formatData(this.filtros.dt_vencimento_ate);
        },
        isUserReadonly() {
            return this.$store.getters.isUserReadonly;
        },
        lenOfertasGlobais() {
            if (this.ofertasGlobais?.length > 0) {
                const ofertasAtivas = this.ofertasGlobais.filter(
                    oferta => oferta.status
                );
                return ofertasAtivas.length;
            }
            return 0;
        },
        lenOfertasTitulo() {
            if (this.ofertas?.length > 0) {
                const ofertasAtivas = this.ofertas.filter(
                    oferta => oferta.status
                );
                return ofertasAtivas.length;
            }
            return 0;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        },
        atributoOrdenacao() {
            if (this.options.sortBy.length > 0) {
                return this.options.sortBy[0];
            }
            return null;
        },
        ordenacaoDesc() {
            if (this.options.sortDesc.length > 0) {
                return this.options.sortDesc[0];
            }
            return false;
        },
        alertaRevisao() {
            let alertas = []

            if (this.titulo.ds_revisao) {

                if (this.titulo.ds_revisao.indexOf('AP') > -1) {
                    alertas.push(
                        'Revisão: Necessário realizar upload de anexo para essa espécie de titulos.'
                    )
                }

                if (this.titulo.ds_revisao.indexOf('ND') > -1) {
                    alertas.push(
                        'Revisão: Nome do devedor maior que 45 caracteres.'
                    )
                }

                if (this.titulo.ds_revisao.indexOf('ED') > -1) {
                    alertas.push(
                        'Revisão: Necessário ajustar endereço do devedor.'
                    )
                }

                if (this.titulo.ds_revisao.indexOf('QD') > -1) {
                    alertas.push(
                        'Revisão: Necessário informar coodevedores.'
                    ) 
                }

            }

            return alertas
        }
    },
    async mounted() {
        this.isMobile = this.$vuetify.breakpoint.smAndDown;
        if (this.$store.getters.isPartner == 1) {
            this.usuarioPartner = true;
        } else {
            this.usuarioRecuperi = true;
        }
        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }
        if (this.$store.getters.isLite == 1) {
            console.log('isLite', this.$store.getters.isLite)
            this.usuarioLite = true;
            this.usuarioBasic = false;
        } else {
            this.usuarioLite = false;
            this.usuarioBasic = true;
        }
        if (this.$store.getters.isLiteRo == 1) {
            this.usuarioLiteRO = true;
        }
        if (this.$store.getters.isAdmin == 1) {
            this.usuarioBasic = false;
            this.usuarioAdmin = true;
        }

        try {
            this.loadingInicial = true;

        }catch(e){
            this.snackbarConsultaTitulo(
                'error',
                'Erro ao buscar Situações de Título.'
            );
        }
        this.loadingInicial = false;

        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }

        this.verificaData();
        this.verificaData3Dias();
        this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
        this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];

        this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
        this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];

        this.filtros.dt_arquivo_movimento_de = this.filtros.dt_arquivo_movimento_range[0];
        this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_arquivo_movimento_range[1];
        if (localStorage.getItem("filtroTitulos")) {
            this.filtros = JSON.parse(localStorage.getItem("filtroTitulos"));
            let outrosFiltros = JSON.parse(localStorage.getItem("outrosFiltros"));
            this.irregularesNaoReapresentados = outrosFiltros.irregularesNaoReapresentados;
            this.comInstrumento = outrosFiltros.comInstrumento;
            this.irregulares = outrosFiltros.naoMostrarIrregulares;
            this.revisao = outrosFiltros.revisao;
            this.options.page = outrosFiltros.page;
        }
        if(localStorage.getItem('resultadoTitulos')) {
            let resultado = JSON.parse(localStorage.getItem('resultadoTitulos'));
            this.titulos = resultado.titulos;
            this.totalTitulos = resultado.total;
        }
        
        
        // let tituloService = new TituloService(Vue.http, this.$store);
        this.situacoesTitulo = this.$store.getters.situacoesTitulo;
        // this.situacoesTitulo.push({
        //     cd_status: 99999,
        //     nm_status: 'EM REVISÃO'
        // });
        // tituloService.listaCarteiras();

        this.loadingAgrupador = true;
        this.loadingIrregularidades = true;
        if(!this.usuarioLiteRO){
            await this.checarAgrupadores();
        }
        await this.buscarIrregularidadesApresentante();
    },
    methods: {
        getOnlyNumbers,
        getDataHoraString,
        getDataAtualDatePicker,
        getDataDatePicker,
        getDataHoraSubtraida,
        listarAgrupadores,
        listarIrregularidades,
        async checarAgrupadores() {
            try {
                if (!this.listaIrregularidades?.length) {
                    await this.listarAgrupadores(this.$store, true);
                }
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao buscar Agrupadores disponíveis.'
                );
            } finally {
                this.loadingAgrupador = false;
            }
        },
        updateSearch() {
            this.wallets = [];
        },
        visualizarEmail(item){
            if(item?.ds_conteudo){
                this.showEmailDialog = true;
                this.emailContent = decodeURIComponent(escape(atob(item.ds_conteudo)));
            }else{
                this.snackbarConsultaTitulo(
                    'primary',
                    'Pré visualização não disponivel'
                );
            }
        },
        fetchWallets(search) {
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService.getWalletWithSearch(search).then(response => {
                this.wallets = response;                
            });
        },
        openModalTrocarContatoDevedor() {
            this.novosContatosdevedor.email = this.titulo.ds_email_devedor;
            this.novosContatosdevedor.celular = this.titulo.nu_telefone_devedor;
            this.modalTrocarContatoDevedor = true;
        },
        trocarContatoDevedor(){
            this.modalTrocarContatoDevedor = false;
            this.novosContatosdevedor.celular = this.novosContatosdevedor.celular.replace(/\D/g, '');
            let payload = {
                id_titulo: this.titulo.id_titulo,
                cd_apresentante: this.titulo.cd_apresentante,
                ds_email_devedor: this.novosContatosdevedor.email,
                nu_telefone_devedor: this.novosContatosdevedor.celular,
                nm_devedor_cra: "",
                ds_endereco_devedor_cra: "",
            }
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService.trocarContatoDevedor(payload)
                .then(async (response) => {
                    console.log(response)
                    if (response.status == 200) {
                        this.snackbarConsultaTitulo(
                            'success',
                            'Contato do devedor alterado com sucesso.'
                        );
                        this.exibirDetalhe(this.ultimoTitulo);
                        this.filtros = this.ultimoFiltro;
                        this.aplicarFiltros();
                    }
                })
                .catch(() => {
                    this.snackbarConsultaTitulo(
                        'error',
                        'Erro ao alterar contato do devedor.'
                    );
                });
            
        },

        alterarStatus() {

            const tituloService = new TituloService(Vue.http, this.$store);
            let cd_irregularidade = this.irregularidadeTipo ? this.irregularidadeTipo : null; 

            tituloService.updateStatus(this.titulo.id_titulo, this.statusTitulo, cd_irregularidade).then(response => {
                if (response.status == 200) {

                    let status_label = {
                        320: 'Negociação',
                        220: 'Protesto'
                    }

                    let msg = cd_irregularidade ? 'Irregularizado título com sucesso por irregularidade.' : `Título enviado para ${status_label[this.statusTitulo]}!`;

                    this.snackbarConsultaTitulo(
                        'success',
                        msg
                    );
                    this.exibirDetalhe(this.ultimoTitulo);
                    this.filtros = this.ultimoFiltro;
                    this.aplicarFiltros();
                }
            }).catch(() => {

                let msg = cd_irregularidade ? 'Erro ao irregularizar título por comarca inválida.' : `Erro ao atualizar status do título.`;

                this.snackbarConsultaTitulo(
                    'error',
                    msg
                );
            });

        },
        desativarMailing(){
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService.desativarMailing(this.titulo).then(response => {
                if (response.status == 200) {
                    this.snackbarConsultaTitulo(
                        'success',
                        'Mailing desativado com sucesso.'
                    );
                    this.exibirDetalhe(this.ultimoTitulo);
                    this.filtros = this.ultimoFiltro;
                    this.aplicarFiltros();
                }
            }).catch(() => {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao desativar Mailing.'
                );
            });

        },
        reloadTable() {
            this.closeDialog();
            this.botaoFiltrar();
        },
        async buscarIrregularidadesApresentante() {
            try {
                await this.listarIrregularidades(Vue.http, this.$store);
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao buscar Irregularidades do Apresentante.'
                );
            } finally {
                this.loadingIrregularidades = false;
            }
        },
        retornarValorInstrumentoProtesto(item, tipo) {
            const status_pre_protesto = [220, 221, 225];
            if (tipo === 'style') {
                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        return 'background-color: ' + this.$vuetify.theme.themes.light.primary;
                    }
                    return 'background-color: green';
                }

                return 'background-color: #b8c4d4';
            }

            if (tipo === 'icon') {
                if (status_pre_protesto.includes(item.cd_status) || item.cd_status >= 300) {
                    return 'mdi-clock-check-outline';
                }

                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        if (item.st_solicita_instrumento) {
                            return 'mdi-clock-check-outline';
                        }
                        return 'mdi-email-outline';
                    }
                    return 'mdi-download';
                }

                return 'mdi-close-circle-outline';
            }

            if (tipo === 'tooltip') {
                if (status_pre_protesto.includes(item.cd_status) || item.cd_status >= 300) {
                    return 'Instrumento de Protesto Indisponível, aguardar Protesto';
                }

                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        if (item.st_solicita_instrumento) {
                            return 'Solicitação de Instrumento de Protesto já realizada, favor aguardar';
                        }
                        return 'Solicitar Instrumento de Protesto';
                    }
                    return 'Baixar Instrumento de Protesto';
                }

                return 'Instrumento de Protesto não está mais disponível.';
            }
        },
        verificarFuncaoInstrumentos(titulo) {
            if (
                titulo.cd_status === 102 &&
                !titulo.id_anexo_instrumento &&
                !titulo.st_solicita_instrumento
            ) {
                this.solicitarInstrumentos(titulo);
            }

            if (
                titulo.cd_status === 102 &&
                titulo.id_anexo_instrumento &&
                titulo.id_anexo_instrumento !== ''
            ) {
                this.baixarInstrumentos(titulo);
            }
        },
        solicitarInstrumentos(titulo) {
            try {
                const tituloService = new TituloService(Vue.http, this.$store);
                const titulo_id = titulo.id_titulo;
                let cd_apresentante = titulo.cd_apresentante;
                if (titulo.cd_comprador) {
                    cd_apresentante = titulo.cd_comprador;
                }

                this.loadingInicial = true;
                tituloService
                    .solicitaInstrumento(titulo_id, cd_apresentante)
                    .then(response => {
                        if (response.status == 200) {
                            titulo.st_solicita_instrumento = 1;
                            this.snackbarConsultaTitulo(
                                'success',
                                'Solicitação de Instrumento de Protesto realizada com sucesso, favor, aguardar'
                            );
                        }
                    })
                    .catch(() => {
                        this.snackbarConsultaTitulo(
                            'error',
                            'Erro ao solicitar Instrumento de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInicial = false;
                    });
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao solicitar Instrumento de Protesto'
                );
                this.loadingInicial = false;
            }
        },
        baixarInstrumentos(titulo) {
            try {
                const tituloService = new TituloService(Vue.http, this.$store);
                this.loadingInicial = true;
                tituloService
                    .baixarInstrumentos(titulo)
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        const nomeDocumento = `${titulo.ds_nosso_numero}_instrumento_protesto.pdf`;
                        link.href = url;
                        link.setAttribute('download', nomeDocumento);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(() => {
                        this.snackbarConsultaTitulo(
                            'error',
                            'Erro ao baixar Instrumento de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInicial = false;
                    });
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao baixar Instrumento de Protesto'
                );
                this.loadingInicial = false;
            }
        },
        listarComentarios() {
            this.listaComentarios = [];
            this.comentario = null;
            let id_titulo = this.titulo.id_titulo;
            return new Promise((resolve, reject) => {
                try {
                    let tituloService = new TituloService(
                        Vue.http,
                        this.$store
                    );
                    tituloService.listarComentarios(id_titulo).then(
                        response => {
                            if (response.status == 200) {
                                this.listaComentarios = response.body;
                                if (this.listaComentarios.length > 0) {
                                    this.habilitaComentarios = true;
                                } else {
                                    this.habilitaComentarios = true;
                                }
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        criarComentario() {
            this.habilitaLoadingComentarios = true;
            this.habilitaComentarios = false;
            let comentario = this.comentario;
            let id_titulo = this.titulo.id_titulo;
            return new Promise((resolve, reject) => {
                try {
                    let tituloService = new TituloService(
                        Vue.http,
                        this.$store
                    );
                    tituloService.criarComentario(comentario, id_titulo).then(
                        response => {
                            if (response.status == 200) {
                                this.habilitaLoadingComentarios = false;
                                this.listarComentarios();
                                this.snackbarDetalheTitulo(
                                    true,
                                    'success',
                                    'Comentário Criado com Sucesso!',
                                    'mdi-text-box-check-outline'
                                );
                            }
                        },
                        error => {
                            console.log(error);
                            this.habilitaLoadingComentarios = false;
                            this.habilitaComentarios = true;
                            this.snackbarDetalheTitulo(
                                true,
                                'error',
                                'Erro ao criar comentário para o título',
                                'mdi-close'
                            );
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        setDataMovimentoFiltro(intervaloDias) {
            const dataSubtraida = this.getDataHoraSubtraida(
                Date.now(),
                intervaloDias
            );

            const dataFinalDatePicker = this.getDataAtualDatePicker();
            const dataInicialDatePicker = this.getDataDatePicker(dataSubtraida);

            this.filtros.dt_arquivo_movimento_range = [
                dataFinalDatePicker,
                dataInicialDatePicker
            ];
        },
        snackbarDetalheTitulo(
            snackbar,
            snackbarColor,
            mensagemSnackBar,
            snackbarIcon
        ) {
            this.snackbarDialog = snackbar;
            this.snackbarColor = snackbarColor;
            this.mensagemRetornoSnackbar = mensagemSnackBar;
            this.snackbarIcon = snackbarIcon;
            this.snackbarTimeout = '5500';
        },
        snackbarConsultaTitulo(snackbarColor, mensagemSnackBar) {
            this.consultaSnackbarDialog = true;
            this.consultaSnackbarColor = snackbarColor;
            this.mensagemRetornoconsultaSnackbar = mensagemSnackBar;
            this.consultaSnackbarTimeout = '5000';
        },
        atualizarTitulo(retorno) {
            const { titulo, historico_titulo } = retorno;
            this.titulo = cloneDeep(titulo);
            this.historicos = cloneDeep(historico_titulo);
            this.loadingInicial = false;
        },
        convertData(item) {
            let dias = item.split(' ')[0];
            let itemSplitado = dias.split('-');
            let dia = itemSplitado[2];
            let mes = itemSplitado[1];
            let ano = itemSplitado[0];
            return `${dia}/${mes}/${ano}`;
        },
        verificarMascara(cpfCnpj) {
            let mascara = '###.###.###-###';
            if (cpfCnpj) {
                const cpfCnpjNumeros = this.getOnlyNumbers(cpfCnpj);
                if (cpfCnpjNumeros.length <= 11) {
                    mascara = '###.###.###-###';
                } else {
                    mascara = 'XX.XXX.XXX/XXXX-##';
                }
            }
            return mascara;
        },
        validarSelecaoData(val, booleano) {
            if (val && val.length === 2) {
                this[booleano] = false;
            }
        },
        verificaStatus() {
            if (this.filtros.cd_status && this.filtros.cd_status.length === 6) {
                this.listaSituacoes.forEach(status => {
                    const statusSelecionado = this.filtros.cd_status.find(
                        cdStatus => cdStatus === status.cd_status
                    );
                    if (!statusSelecionado) {
                        status.disabled = true;
                    }
                });
            } else {
                this.listaSituacoes.forEach(status =>
                    status.nm_status !== 'Selecione Status'
                        ? (status.disabled = false)
                        : ''
                );
            }
        },
        verificaStEnvio() {
            if (this.titulo.st_envio === -1 || this.titulo.st_envio === 1) {
                if (this.titulo.dt_atualizacao > this.dt_ontem) {
                    //aqui habilita o botão
                    this.desabilitaBotaoFila = false;
                } else {
                    this.desabilitaBotaoFila = true;
                }
            } else {
                this.desabilitaBotaoFila = true;
            }
        },
        verificaData() {
            let dataAtual = new Date();
            let ano = dataAtual.getFullYear();
            let mes = dataAtual.getMonth() + 1;
            let dia = dataAtual.getDate() - 1;
            let hora = dataAtual.getHours();
            let minutos = dataAtual.getMinutes();
            let segundos = dataAtual.getSeconds();
            this.dt_ontem =
                ano +
                '-' +
                ('0' + mes).slice(-2) +
                '-' +
                dia +
                ' ' +
                hora +
                ':' +
                minutos +
                ':' +
                segundos;
        },
        verificaData3Dias() {
            let dataAtual = new Date();
            let ano = dataAtual.getFullYear();
            let mes = dataAtual.getMonth() + 1;
            let dia = dataAtual.getDate() - 3;
            let hora = dataAtual.getHours();
            let minutos = dataAtual.getMinutes();
            let segundos = dataAtual.getSeconds();
            this.dt_ontem_desistencia =
                ano +
                '-' +
                ('0' + mes).slice(-2) +
                '-' +
                dia +
                ' ' +
                hora +
                ':' +
                minutos +
                ':' +
                segundos;
        },
        verificarCancelarComandoTitulo(titulo) {
            const statusPermitidos = [230, 240, 250, 252];
            if (
                Number(titulo.st_envio) === 0 &&
                statusPermitidos.includes(Number(titulo.cd_status))
            ) {
                return true;
            }
            return false;
        },
        modalCancelarComando() {
            this.tituloModal = 'Cancelamento de Comando';
            this.textoModal =
                'O Cancelamento de Comando pode não ser aceito. Para assegurar o sucesso da solicitação, recomendamos acompanhar o título. <br /><br />Deseja prosseguir?';
            this.btnConfirmarEmit = 'cancelarComando';
            this.mostrarConfirmacao = true;
        },
        cancelarComando() {
            this.mostrarConfirmacao = false;
            const limiteInicial = 14 * 60 + 30; // 14h30 transformado em minutos
            const limiteFinal = 15 * 60 + 30; // 15h30 transformado em minutos
            const dataAtual = new Date();
            const horaAtual =
                dataAtual.getHours() * 60 + dataAtual.getMinutes(); // hora da solicitação transformada em minutos
            if (horaAtual < limiteInicial || limiteFinal < horaAtual) {
                let { id_titulo, cd_apresentante, cd_status } = this.titulo;
                if (this.titulo.cd_comprador) {
                    cd_apresentante = this.titulo.cd_comprador;
                }
                this.loadingInicial = true;
                this.tituloService
                    .cancelarComando(id_titulo, cd_apresentante, cd_status)
                    .then(
                        response => {
                            if (response.status == 200) {
                                this.snackbarDetalheTitulo(
                                    true,
                                    'success',
                                    'Solicitação de Cancelamento de Comando realizada com sucesso.',
                                    'mdi-text-box-check-outline'
                                );
                                this.setAtualizarTitulo = true;
                                setTimeout(() => {
                                    this.setAtualizarTitulo = false;
                                }, 250);
                            }
                        },
                        error => {
                            console.log(error.status);
                            this.snackbarDetalheTitulo(
                                true,
                                'error',
                                'Erro ao realizar solicitação.',
                                'mdi-alert-circle-outline'
                            );
                            this.loadingInicial = false;
                        }
                    );
            } else {
                this.snackbarDetalheTitulo(
                    true,
                    'error',
                    'Solicitação não permitida entre 14:30 e 15:30. Tente novamente mais tarde.',
                    'mdi-alert-circle-outline'
                );
            }
        },
        recolocarEmFila(titulo) {
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.recolocarEmFilaDeEnvio(titulo).then(
                response => {
                    if (response.status == 200) {
                        this.snackbarDetalheTitulo(
                            true,
                            'success',
                            'Título atualizado com sucesso: Situação do envio: 0',
                            'mdi-text-box-check-outline'
                        );
                        this.desabilitaBotaoFila = true;
                    }
                },
                error => {
                    if (error.status == 400) {
                        this.snackbarDetalheTitulo(
                            true,
                            'error',
                            'Alteração não permitida: Erro 400',
                            'mdi-alert-circle-outline'
                        );
                    }
                    console.log(error.body);
                }
            );
        },
        formatDate(item) {
            item = new Date(item);
            item = item.toLocaleDateString();
            const [month, day, year] = item.split('/');
            return `${day}/${month}/${year}`;
        },
        limparFiltros() {
            this.irregularesNaoReapresentados = false;
            this.comInstrumento = false;
            this.irregulares = false;
            this.revisao = false;
            this.filtros.ds_nosso_numero = null;
            this.filtros.nu_identificacao_devedor = null;
            this.filtros.nm_devedor = null;
            this.filtros.cd_status = null;
            this.limparDataCadastro();
            this.limparDataMovimento();
            this.filtros.cd_uf_devedor = null;
            this.filtros.cd_carteira = null;
            localStorage.removeItem('filtroTitulos');
            localStorage.removeItem('outrosFiltros');
        },
        closeDialog() {
            this.dialog = false;
            this.snackbarLateral = true;
            this.apresentanteProtestado = null;
            this.historicoEmails = null;
            this.historicoCheck = false;
        },
        listarEmailsEnviados() {
            let tituloSelecionado = this.titulo;
            return new Promise((resolve, reject) => {
                try {
                    let emailService = new EmailService(Vue.http, this.$store);
                    emailService.listarHistorico(tituloSelecionado).then(
                        response => {
                            if (response.status == 200) {
                                if (response.body == '') {
                                    this.historicoCheck = false;
                                    this.historicoVazio = true;
                                } else {
                                    this.historicoEmails = response.body;
                                    tituloSelecionado = null;
                                    this.historicoVazio = false;
                                    this.historicoCheck = true;
                                }
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                    if (this.$store.getters.NomeExibicaoApresentante == null) {
                        this.NomeExibicaoApresentante = this.titulo.nm_sacador;
                    } else {
                        this.NomeExibicaoApresentante = this.$store.getters.NomeExibicaoApresentante;
                    }
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        somaCounter() {
            this.counter = this.counter + 1;
        },
        checkForm() {
            if (this.validEmail) {
                return false;
            }
            return true;
        },
        checkformSubmit() {
            const check = this.checkForm();
            if (!check) {
                this.exportarEmailSQS();
            }
        },
        dataVencimento() {
            this.filtros.dt_vencimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_vencimento_range[0]),
                Formatter.formatData(this.filtros.dt_vencimento_range[1])
            ];
            if (this.filtros.dt_vencimento_range_fmt[1] == null) {
                return this.filtros.dt_vencimento_range_fmt[0];
            } else if (
                this.filtros.dt_vencimento_range_fmt[0] >
                this.filtros.dt_vencimento_range_fmt[1]
            ) {
                this.filtros.dt_vencimento_range_fmt = this.filtros.dt_vencimento_range_fmt.reverse();
                return this.filtros.dt_vencimento_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_vencimento_range_fmt.join(' - ');
            }
        },
        dataCadastro() {
            this.filtros.dt_cadastro_range_fmt = [
                Formatter.formatData(this.filtros.dt_cadastro_range[0]),
                Formatter.formatData(this.filtros.dt_cadastro_range[1])
            ];
            if (this.filtros.dt_cadastro_range[1] == null) {
                return this.filtros.dt_cadastro_range_fmt[0];
            } else if (
                this.filtros.dt_cadastro_range_fmt[0] >
                this.filtros.dt_cadastro_range_fmt[1]
            ) {
                this.filtros.dt_cadastro_range_fmt = this.filtros.dt_cadastro_range_fmt.reverse();
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            }
        },
        dataMovimentacao() {
            this.filtros.dt_arquivo_movimento_range_fmt = [
                Formatter.formatData(
                    this.filtros.dt_arquivo_movimento_range[0]
                ),
                Formatter.formatData(this.filtros.dt_arquivo_movimento_range[1])
            ];
            if (this.filtros.dt_arquivo_movimento_range[1] == null) {
                return this.filtros.dt_arquivo_movimento_range_fmt[0];
            } else if (
                this.filtros.dt_arquivo_movimento_range[0] >
                this.filtros.dt_arquivo_movimento_range[1]
            ) {
                this.filtros.dt_arquivo_movimento_range_fmt = this.filtros.dt_arquivo_movimento_range_fmt.reverse();
                return this.filtros.dt_arquivo_movimento_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_arquivo_movimento_range_fmt.join(' - ');
            }
        },
        habilitaDialogCorrecao() {
            this.habilitaCorrecao = !this.habilitaCorrecao;
        },
        desabilitaDialogCorrecao() {
            this.habilitaCorrecao = !this.habilitaCorrecao;
            this.filtros = this.ultimoFiltro;
            this.aplicarFiltros();
            this.exibirDetalhe(this.titulo);
        },
        habilitaDialOferta(titulo) {
            this.exibirOferta(titulo);
        },
        desabilitaDialogOferta() {
            this.habilitaOferta = false;
            this.exibirDetalhe(this.titulo);
        },
        botaoFiltrar() {
            this.filtrar = true;
            this.options.page = 1;
            this.aplicarFiltros();
        },
        confirmaCancelamento() {
            this.solicitaCancelamentoPadrao();
        },

        confirmaCancelamentoDesistencia() {
            this.solicitaCancelamentoPadrao();
            this.dialogInfoDesistencia = false;
        },
        fechaDialogInfoDesistencia() {
            this.dialogInfoDesistencia = false;
        },
        negaCancelamento() {
            this.dialogConfirmation = false;
            this.modalInformativoPreAnuencia = false;
            this.dialogExtra = null;
            this.labelConfirm = null;
        },
        fechaCancelamento() {
            this.dialogConfirmation = false;
            this.dialogResponse = false;
            this.exibirDetalhe(this.ultimoTitulo);
            this.filtros = this.ultimoFiltro;
            this.aplicarFiltros();
        },
        limparDataVencimento() {
            this.filtros.dt_vencimento_range = [];
        },
        limparDataCadastro() {
            this.filtros.dt_cadastro_range = [];
        },
        limparDataMovimento() {
            this.filtros.dt_arquivo_movimento_range = [];
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        colorOferta(valor) {
            if (valor) {
                return 'green';
            } else {
                return 'red';
            }
        },

        solicitaCancelamentoPadrao() {
            this.dialogConfirmation = false;
            this.loadingCancelamento = true;
            let cd_apresentante = this.titulo.cd_apresentante;
            if (this.titulo.cd_comprador) {
                cd_apresentante = this.titulo.cd_comprador;
            }
            return new Promise((resolve, reject) => {
                try {
                    let service = new CancelamentoService(
                        Vue.http,
                        this.$store
                    );
                    service
                        .cancelamentoTitulo(
                            this.titulo.id_titulo,
                            this.tipo_path,
                            cd_apresentante
                        )
                        .then(
                            response => {
                                if (response.status == 200) {
                                    this.loadingCancelamento = false;
                                    this.modalInformativoPreAnuencia = false;
                                    this.dialogText =
                                        'Título ' +
                                        this.titulo.id_titulo +
                                        ' enviado ' +
                                        this.tipo_cancelamento +
                                        '!';
                                    this.dialogConfirmation = true;
                                    this.dialogResponse = true;
                                    this.snackbarDetalheTitulo(
                                        true,
                                        'success',
                                        'Título atualizado com sucesso',
                                        'mdi-text-box-check-outline'
                                    );
                                    this.exibirDetalhe(this.ultimoTitulo);
                                    this.filtros = this.ultimoFiltro;
                                    this.aplicarFiltros();
                                }
                            },
                            error => {
                                let message = '';
                                if (error?.body?.msg) {
                                    message = error.body.msg;
                                }
                                this.loadingCancelamento = false;
                                this.dialogText =
                                    'Erro ao enviar ' +
                                    this.tipo_cancelamento +
                                    'do título ' +
                                    this.titulo.id_titulo +
                                    ': ' +
                                    message;
                                this.dialogConfirmation = true;
                                this.dialogResponse = true;
                                this.mensagem = error;
                                this.snackbarDetalheTitulo(
                                    true,
                                    'error',
                                    'Erro ao realizar solicitação',
                                    'mdi-close'
                                );
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                } finally {
                    this.dialogExtra = null;
                    this.labelConfirm = null;
                }
            });
        },

        confirmaCancelamentoNegociacao() {
            this.dialogCancelamentoNegociacao = false;
            this.loadingCancelamento = true;

            try {

                let service = new TituloService(
                    Vue.http,
                    this.$store
                );

                let data = {
                    "titulos": [
                        {
                            "id_titulo": this.titulo.id_titulo
                        }
                    ]
                };

                service
                    .cancelaNegociacao(
                        data
                    )
                    .then(
                        response => {
                            if (response.status == 200) {
                                this.loadingCancelamento = false;

                                this.dialogText= `Solicitação de cancelamento de negociação para o título ${this.titulo.ds_nosso_numero} enviada com sucesso!`;

                                this.dialogConfirmation = true;
                                this.dialogResponse = true;
                                this.snackbarDetalheTitulo(
                                    true,
                                    'success',
                                    'Título atualizado com sucesso',
                                    'mdi-text-box-check-outline'
                                );
                                this.exibirDetalhe(this.ultimoTitulo);
                                this.filtros = this.ultimoFiltro;
                                this.aplicarFiltros();
                            }
                        },
                        error => {
                            let message = '';
                            if (error?.body?.titulos) {
                                message = error.body.titulos[0]['error'][0];
                            }

                            this.loadingCancelamento = false;

                            this.dialogText= `Erro ao enviar solicitação de cancelamento de negociação para o titulo ${this.titulo.ds_nosso_numero}: ${message}`;

                            this.dialogConfirmation = true;
                            this.dialogResponse = true;
                            this.mensagem = error;

                            this.snackbarDetalheTitulo(
                                true,
                                'error',
                                'Erro ao realizar solicitação',
                                'mdi-close'
                            );
                        }
                    );
            } catch (e) {
                this.mensagem = e;
                console.log(e);
            } finally {
                this.dialogExtra = null;
                this.labelConfirm = null;
            }

        },

        solicitarAleracaoStatus(cd_status, irregularidade=null){

            let title = '';
            let text = '';

            switch(cd_status){
                case 320:
                    title = 'Enviar para Negociação'
                    text = 'Deseja realmente enviar o titulo para Negociação?'
                    break;
                case 220:
                    title = 'Enviar para Protesto'
                    text = 'Deseja realmente enviar o titulo para Protesto?'
                    break;

                case 105:
                    title = 'Irregularizar Título'
                    text = 'Deseja realmente irregularizar o título devido a comarca estar inválida?'
                    break;
            }

            this.statusTitulo = cd_status;
            this.irregularidadeTipo = irregularidade;
            this.tipo_path = 'alterar-status';
            this.genericDialog.title = title;
            this.genericDialog.text = text;
            this.genericDialog.labelConfirmBtn = 'Sim';
            this.genericDialog.labelCancelBtn = 'Não';
            this.dialogConfirmationGeneric = true;
        },

        confirmaDialogGeneric() {
            this.dialogConfirmationGeneric = false;
            this.genericDialog = {
                title: '',
                text: '',
                labelConfirmBtn: '',
                labelCancelBtn: ''
            };
       
            if (this.tipo_path == 'alterar-status') {
                this.alterarStatus();
            }
        },
        cancelDialogGeneric() {
            this.dialogConfirmationGeneric = false;
            this.genericDialog = {
                title: '',
                text: '',
                labelConfirmBtn: '',
                labelCancelBtn: ''
            };
        },

        solicitaCancelamentoNegociacao() {
            this.dialogCancelamentoNegociacao = true;
        },

        solicitaCancelamento() {
            this.tipo_path = 'cancelamento';
            this.tipo_cancelamento = 'o Cancelamento';
            this.dialogConfirmation = true;
            this.dialogExtra =
                'O comando de cancelamento gera custos de responsabilidade do apresentante.';
            this.labelConfirm = 'Sim, estou ciente';
        },
        solicitaAnuencia() {
            this.tipo_path = 'anuencia';
            this.tipo_cancelamento = 'a Anuência';
            this.dialogConfirmation = true;
            if ([221, 225].includes(this.titulo.cd_status)) {
                this.modalInformativoPreAnuencia = true;
            }
            // }
        },
        solicitaDesistencia() {
            if (this.titulo.dt_atualizacao < this.dt_ontem_desistencia) {
                this.dialogInfoDesistencia = true;
                this.tipo_path = 'desistencia';
                this.tipo_cancelamento = 'a Desistência';
            } else {
                this.tipo_path = 'desistencia';
                this.tipo_cancelamento = 'a Desistência';
                this.dialogConfirmation = true;
                this.dialogExtra =
                    'O comando de desistência gera custos de responsabilidade do apresentante.';
                this.labelConfirm = 'Sim, estou ciente';
            }
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        formatDataVencimento(titulo) {
            return Formatter.formatData(titulo.dt_vencimento);
        },
        formatData(item) {
            return Utilitarios.formatarDataHoraMoment(item);
        },
        //formatValorTitulo(titulo) {
        ///  return Formatter.formatDinheiro(titulo.vl_titulo);
        //},
        aplicarFiltros() {
            let outrosFiltros = {};
            this.filtros.ignorar_cancelados = this.ignorar_cancelados ? 1 : 0;
            outrosFiltros.irregularesNaoReapresentados = this.irregularesNaoReapresentados
            outrosFiltros.comInstrumento = this.comInstrumento
            outrosFiltros.naoMostrarIrregulares = this.irregulares
            outrosFiltros.revisao = this.revisao
            outrosFiltros.page = this.options.page
            if (this.revisao == true) {
                this.filtros.ds_revisao = '1';
            } else {
                this.filtros.ds_revisao = '';
            }
            if (this.irregulares == true) {
                this.filtros.irregulares = '1';
            } else {
                this.filtros.irregulares = '0';
            }
            if (this.oferta == true) {
                this.filtros.oferta = '1';
            } else {
                this.filtros.oferta = '';
            }

            this.ultimoFiltro = lodash.cloneDeep(this.filtros);
            if (this.comInstrumento) {
                this.ultimoFiltro.tem_ip_anexo = '1';
            }
            if (this.irregularesNaoReapresentados) {
                this.ultimoFiltro.tem_irr_nao_reapresentado = '1';
            }
            localStorage.setItem('filtroTitulos', JSON.stringify(this.ultimoFiltro));
            localStorage.setItem('outrosFiltros', JSON.stringify(outrosFiltros));
            this.buscarTitulos().then(data => {


                this.titulos = data.titulos;
                this.totalTitulos = data.total;
                localStorage.setItem('resultadoTitulos', JSON.stringify(data));


                
                if (data.total === 0) {
                    this.mensagem = true;
                    this.mensagemRetorno =
                        'A Consulta não retornou nenhum titulo.';
                    this.alertType = null;
                    this.alertColor = 'grey';
                    this.numero_paginas = 1;
                } else {
                    this.mensagem = false;
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
                this.sorterFilter();
            });
        },
        sorterFilter() {
            return new Promise(resolve => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                let items = this.titulos;
                const total = items.length;

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]];
                        const sortB = b[sortBy[0]];

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1;
                            if (sortA > sortB) return -1;
                            return 0;
                        } else {
                            if (sortA < sortB) return -1;
                            if (sortA > sortB) return 1;
                            return 0;
                        }
                    });
                }

                if (itemsPerPage > 0) {
                    items = items.slice(
                        (page - 1) * itemsPerPage,
                        page * itemsPerPage
                    );
                }

                setTimeout(() => {
                    resolve({
                        items,
                        total
                    });
                }, 1000);
            });
        },
        buscarTitulos() {
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            if (this.ultimoFiltro == null) {
                this.ultimoFiltro = lodash.cloneDeep(this.filtros);
            }
            this.ultimoFiltro.nu_identificacao_devedor = this.getOnlyNumbers(
                this.ultimoFiltro.nu_identificacao_devedor
            );
            if (this.atributoOrdenacao) {
                const orientacaoOrdenacao = this.ordenacaoDesc ? 'DESC' : 'ASC';
                this.ultimoFiltro.orderby = `${this.atributoOrdenacao} ${orientacaoOrdenacao}`;
            }
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(this.ultimoFiltro, page, itemsPerPage)
                        .then(
                            response => {
                                if (response.status == 200) {
                                    response.status;
                                    response.statusText;
                                    response.headers.get('Expires');
                                    this.exportActive = false;
                                    const {
                                        titulos,
                                        numero_linhas: total
                                    } = response.body;

                                    this.loadingFiltros = false;
                                    this.loadingInicial = false;
                                    this.loading = false;
                                    resolve({
                                        titulos,
                                        total
                                    });
                                }
                            },
                            error => {
                                if (
                                    error.body.msg ==
                                    'Session expired! Authentication is required!'
                                ) {
                                    this.loadingFiltros = false;
                                    this.loadingInicial = false;
                                    this.loading = false;
                                    this.mensagem = true;
                                    this.mensagemRetorno =
                                        'Sua sessão expirou devido ao tempo de inatividade, faça login novamente para ter acesso ao sistema (' +
                                        error.body.msg +
                                        ') ';
                                    this.alertType = 'Error';
                                    this.alertColor = 'error';
                                    console.log(error);
                                }
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        exibirDetalhe(titulo) {
            this.snackbarLateral = false;
            this.$store.commit('setTitulo', titulo);
            this.ultimoTitulo = titulo;
            this.dialogLoading = true;
            this.somaCounter();
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.detalharTitulo(titulo).then(
                response => {
                    const { detalhe_titulo, historico_titulo } = response.body;
                    this.titulo = detalhe_titulo;
                    this.isSaoPaulo =
                        this.titulo.cd_uf_devedor === 'SP' ? true : false;
                    this.historicos = historico_titulo;
                    this.dialog = true;
                    this.dialogLoading = false;
                    this.listarEmailsEnviados();
                    this.verificaStEnvio();
                    if (this.superUsuario === true) {
                        // this.verificarApresentanteProtestado();
                        if (!this.usuarioPartner) {
                            this.listarComentarios(titulo);
                        }
                    }
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        exibirOferta: function(titulo) {
            this.dialogLoading = true;
            this.verificarOfertasTituloProtestado(titulo, true);
        },
        verificarOfertasTituloProtestado(abrirModal = false) {
            let id_titulo = this.titulo.id_titulo;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            this.ofertaTituloLoading = true;
            ofertaService
                .buscarOfertasPorTitulo(id_titulo)
                .then(
                    response => {
                        this.habilitaOferta = abrirModal;
                        this.ofertas = [];
                        this.ofertas = response.data;
                    },
                    error => {
                        console.error(error);
                    }
                )
                .finally(() => {
                    this.dialogLoading = false;
                    this.ofertaTituloLoading = false;
                });
        },
        verificarApresentanteProtestado() {
            this.ofertaGlobalLoading = true;
            let p_apresentante;
            const apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .consultaApresentanteProtestado(p_apresentante)
                .then(
                    response => {
                        this.apresentanteProtestado = response.body;
                        this.verificaTituloDetalhesProtestado();
                        this.verificarOfertasTituloProtestado();
                    },
                    error => {
                        console.log(error);
                        this.ofertaGlobalLoading = false;
                    }
                );
        },
        verificaTituloDetalhesProtestado() {
            const p_apresentante_id = this.apresentanteProtestado.id;
            const p_titulo_id = this.titulo.id_titulo;
            const ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService
                .consultaDetalhesTitulo(p_apresentante_id, p_titulo_id)
                .then(
                    response => {
                        this.ofertasGlobais =
                            response.body.apresentante_ofertas;
                    },
                    error => {
                        this.mensagem = error;
                    }
                )
                .finally(() => (this.ofertaGlobalLoading = false));
        },
        exportarXls() {
            if (this.totalTitulos > 5000) {
                this.modelRelatorioXlsEmail = true;
                return;
            } else {
                let tituloService = new TituloService(Vue.http, this.$store);
                this.loadingExport = true;
                tituloService
                    .exportarTitulosXls(this.ultimoFiltro)
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            'Relatorio_Detalhado.xlsx'
                        );
                        document.body.appendChild(link);
                        link.click();
                        this.loadingExport = false;
                    })
                    .catch(() => {
                        console.log('error occured');
                        this.loadingExport = false;
                    });
            }
        },
        exportarEmailSQS() {
            // alert("SQS")
            // return
            this.filtros.tem_irr_nao_reapresentado = this.irregularesNaoReapresentados ? '1' : '0';
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingExport = true;
            var emails = [];
            emails[0] = this.emailReport;

            tituloService
                .exportarTitulosEmails(this.filtros, emails, true)
                .then(response => {
                    console.log(response);
                    this.modelRelatorioXlsEmail = false;
                    this.mostraConfirmacaoRelatorioEmail = true;
                    this.emailReport = null;
                    this.loadingExport = false;
                })
                .catch(() => {
                    this.loadingExport = false;
                    this.modelRelatorioXlsEmail = false;
                    this.emailReport = null;
                });
        },
        exportarEmailDetalhado() {
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingExport = true;
            var emails = [];
            emails[0] = this.emailReport;

            tituloService
                .relatoriodetalhes(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.modelRelatorioDetalhado = false;
                    this.mostraConfirmacaoRelatorioEmailDetalhado = true;
                    this.emailReport = null;
                    this.loadingExport = false;
                })
                .catch(() => {
                    this.loadingExport = false;
                    this.modelRelatorioDetalhado = false;
                    this.emailReport = null;
                });
        },
        exportarEmailPdfSimples() {
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingExport = true;
            var emails = [];
            emails[0] = this.emailReport;

            tituloService
                .relatorioPdfEmail(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.modelListaPdfSimples = false;
                    this.mostraConfirmacaoPdf = true;
                    this.emailReport = null;
                    this.loadingExport = false;
                })
                .catch(() => {
                    this.loadingExport = false;
                    this.modelListaPdfSimples = false;
                    this.emailReport = null;
                });
        },
        tituloImpressao(titulo) {
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingTituloImpressao = true;
            tituloService
                .tituloimpressao(titulo)
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'título.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.loadingTituloImpressao = false;
                })
                .catch(() => {
                    this.loadingTituloImpressao = false;
                });
        },
        // Função Histórico Impressão
        historicoImpressao(titulo) {
            if (this.historicoEmails && this.historicoEmails.length > 0) {
                let tituloService = new TituloService(Vue.http, this.$store);
                this.loadingTituloImpressao = true;
                tituloService
                    .historicoImpressao(titulo)
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'histórico-Titulo.pdf');
                        document.body.appendChild(link);
                        link.click();
                        this.loadingTituloImpressao = false;
                    })
                    .catch(() => {
                        this.loadingTituloImpressao = false;
                    });
            } else {
                this.snackbarDetalheTitulo(
                    true,
                    'error',
                    'Não há registros de histórico de e-mails para este título.',
                    'mdi-information-outline'
                );
            }
        },
        relatorioPdfSimples() {
            if (this.totalTitulos > 1000) {
                this.modelListaPdfSimples = true;
                return;
            } else {
                let tituloService = new TituloService(Vue.http, this.$store);
                this.loadingRelatorioPDF = true;
                tituloService
                    .relatorioPdfTela(this.ultimoFiltro)
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Relatório.pdf');
                        document.body.appendChild(link);
                        link.click();
                        this.loadingRelatorioPDF = false;
                    })
                    .catch(() => {
                        this.loadingRelatorioPDF = false;
                    });
            }
        },
        relatorioDetalhes() {
            if (this.totalTitulos > 0) {
                this.modelRelatorioDetalhado = true;
                return;
            }
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingExport = true;
            var emails = this.emailReport;

            tituloService
                .relatoriodetalhes(this.ultimoFiltro, emails)
                .then(response => {
                    console.log(response);
                    this.modelRelatorioDetalhado = false;
                    this.mostraConfirmacaoRelatorioEmailDetalhado = true;
                    this.emailReport = null;
                    this.loadingExport = false;
                })
                .catch(() => {
                    this.loadingExport = false;
                    this.modelRelatorioDetalhado = false;
                    this.emailReport = null;
                });
        }
    }
};
</script>

<style lang="scss">
    .title-details-head {
        display: flex;
        flex-direction: column;

        header {
            width: 100%;
        }

        .revisao-alerta {
            width: 100%;
            background: #F44336;
            text-align: center;
            color: #fff;
            font-size: 15px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-weight: 400;
        }
    }
</style>